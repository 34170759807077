// @ts-nocheck
import {defineStore} from 'pinia'
import {ref} from 'vue'

export const useAuthStore = defineStore('auth', () => {
    
    const jwt_string = ref(undefined)
    const roles = ref([])
    const impersonatingUserIdentifier = ref("")
    const currentUserIdentifier = ref(undefined)
    const firebaseCloudMessagingRegistrationToken: Ref<undefined> = ref(undefined);
    
    const purgeAuth = () => {
        setImpersonation('')
        setRoles(undefined)
        setToken()
    }

    const isRoleCandidate = () => roles.value.includes('ROLE_CANDIDATE')
    const isRoleEmployee = () => roles.value.includes('ROLE_EMPLOYEE')
    const isRoleConsultant = () => roles.value.includes('ROLE_CONSULTANT')
    const setRoles = (value: string[] | undefined): void => {
        roles.value = value || []
    }

    const getCurrentUserIdentifier = () => currentUserIdentifier.value
    const setCurrentUserIdentifier = (value: any): void => {
        currentUserIdentifier.value = value
    }

    const getFCMToken = () => firebaseCloudMessagingRegistrationToken.value
    const setFCMToken = (token: string | undefined): void => {
        firebaseCloudMessagingRegistrationToken.value = token
    }
    
    const getToken = () => jwt_string.value
    const hasToken = () => getToken() !== undefined
    
    const setToken = (newToken: string | undefined) => {        
        if (jwt_string.value !== newToken) {
            // const oldToken = jwt_string.value
            jwt_string.value = newToken
            // emitter.emit('auth:token:update', {newToken,  oldToken})
        }
    }
    
    const canImpersonate = () => roles.value.includes('ROLE_ALLOWED_TO_SWITCH')
    const isImpersonating = () => isRoleConsultant() && impersonatingUserIdentifier.value != ""
    const getImpersonationUsername = () => impersonatingUserIdentifier.value

    const setImpersonation = (userIdentifier: string | undefined): void => {
        impersonatingUserIdentifier.value = userIdentifier
    }

    return {
        jwt_string,
        roles,
        impersonatingUserIdentifier,
        currentUserIdentifier,
        firebaseCloudMessagingRegistrationToken,     

        purgeAuth,

        getToken,
        hasToken,
        setToken,
        getCurrentUserIdentifier,
        setCurrentUserIdentifier,
        setRoles,
        isRoleCandidate,
        isRoleEmployee,
        isRoleConsultant,
        canImpersonate,
        setImpersonation,
        getImpersonationUsername,
        isImpersonating,
        setFCMToken,
        getFCMToken
    }
}, {
    persist: true,
})
