<template>
    <div>
        <div
            v-for="demandGroup in demandGroupedByJobsite">
            <RichResponseListItem
                class="demand-list-item--jobsite"
                iconType="image"
                :icon="getImageForJobsite(demandGroup.jobsite)"
                :title="`${demandGroup.jobsite?.house?.abbreviation?.toString()}${demandGroup.jobsite?.house?.abbreviation ? ' - ' : ''}${demandGroup.jobsite?.abbreviation?.toString()}`"
                :text="demandGroup.jobsite?.client?.abbreviation?.toString()"
                :button="true"
                @click="onClickButtonMoreInfo(demandGroup.jobsite)"
                />
            <RichResponseListItem
                class="demand-list-item--demand"
                v-for="item in demandGroup.demand"
                iconType="date"
                :title="datetime.formatKapitelDateTime(item.begin, 'd. MMMM yyyy')"
            >
                <ion-chip
                    class="small"
                >
                    {{ item.shiftType }}
                </ion-chip>
                {{ datetime.formatKapitelDateTime(item.begin, datetime.kapitelDateTimeFormatTime) }} - {{ datetime.formatKapitelDateTime(item.until, datetime.kapitelDateTimeFormatTime) }} Uhr
            
                <template v-slot:end>
                    <ion-button
                        :color="isDemandWished(item) ? 'success' : 'secondary'"
                        @click="updateWishDemandForDemand(item)">
                        <ion-spinner class="wish-demand-ion-spinner" slot="start" color="light" name="crescent" v-if="item.id && wishDemandActionIsProcessing[item.id]" />
                        <ion-icon
                            v-else-if="isDemandWished(item)"
                            slot="start"
                            src="/icons/check.svg" />
                        <ion-icon
                            v-else
                            slot="start"
                            src="/icons/plus.svg" />
                        Wunschliste
                    </ion-button>
                </template>
            </RichResponseListItem>
        </div>

        <JobsiteDetails
            v-model="jobsiteDetailModelValue"
        />
    </div>
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import datetime from "@/helper/datetime/datetime"
import JobsiteDetails from "@/views/JobsiteDetails.vue"
import { getImageForJobsite } from '@/helper/jobsite'
import { IonChip, IonButton, IonIcon, IonSpinner } from '@ionic/vue'
import { computed, ref } from "vue"
import { Demand, Jobsite } from "@/graphql/generated/graphql"
import { getDemand } from "@/helper/demand"
import { getWishDemand, addWishDemand, removeWishDemand } from "@/helper/wishDemand"

type DemandGroup = {
  jobsite: Jobsite | undefined;
  demand: Demand[];
}

type IntegerBooleanMap = {
  [key: number]: boolean;
}

const props = defineProps<{
    initData: number[]
}>()

const demand = ref<Demand[]>([])
const wishDemand = ref<Demand[]>([])
const jobsiteDetailModelValue = ref()
const wishDemandActionIsProcessing = ref<IntegerBooleanMap>({})

const demandGroupedByJobsite = computed(() => demand.value.reduce((acc: DemandGroup[], val: Demand) => {
    const group = acc.find((gr: DemandGroup) => gr.jobsite?.id === val.jobsite?.id)

    if (group) {
        group.demand.push(val)
    }
    else {
        acc.push({
            jobsite: val.jobsite || undefined,
            demand: [val]
        })
    }

    return acc
}, []))

const isDemandWished = (demand: Demand) => wishDemand.value.some(wd => wd.id === demand.id)

const fetchDemandByIds = (demandIds: number[]) => getDemand(demandIds).then((res: Demand[]) => {
    console.log(res)
    demand.value = res || []
})

const fetchWishDemand = () => getWishDemand().then((res: Demand[]) => {
    wishDemand.value = res || []
})

const updateWishDemandForDemand = (demand: Demand) => {
    if (demand.id) {
        wishDemandActionIsProcessing.value[demand.id] = true
    }

    const action = isDemandWished(demand) ? removeWishDemand : addWishDemand

    action(demand)
        .then(fetchWishDemand)
        .then(() => {
            if (demand.id) {
                wishDemandActionIsProcessing.value[demand.id] = false
            }
    })
}

const onClickButtonMoreInfo = (jobsite: Jobsite|undefined) => {
    if (!jobsite) {
        return
    }

    jobsiteDetailModelValue.value = jobsite.id
}

fetchDemandByIds(props.initData)
fetchWishDemand()
</script>

<style lang="scss">
.wish-demand-ion-spinner {
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.5em;
}

.demand-list-item--jobsite {
    margin-bottom: 0;

    &::part(native) {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.demand-list-item--demand {
    margin-top: 0 !important;

    &:not(:last-child) {
        &::part(native) {
            border-radius: 0 !important;
        }
    }

    &:last-child {
        margin-bottom: 1em;
        
        &::part(native) {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
}
</style>
