<template>    
    <IonPage>
        <IonContent fullscreen padding="true">

            <template v-if="true">
                <Day
                    @click:date="(d) => showModalDate = d.dateId"
                    :calendarDateSingleton="getCalendarDateSingleton('2025-01-01')"
                    :day="{availability: {date:'2025-01-01', constraints: [], shifts: [{type: ShiftType.Fd, begin: '2025-01-01T06:00', until: '2025-01-01T14:00'}]}, demands:[{shiftType: ShiftType.Sd, jobsite} as Demand]}"
                ></Day>
                <Day
                    @click:date="(d) => showModalDate = d.dateId"
                    :calendarDateSingleton="getCalendarDateSingleton('2025-01-02')"
                    :day="{booking: {begin:'2025-01-02T12:00', until:'2025-01-02T18:00', shiftType: ShiftType.Sd, jobsite} as Booking}"
                ></Day>
                <Day
                    @click:date="(d) => showModalDate = d.dateId"
                    :calendarDateSingleton="getCalendarDateSingleton('2025-01-03')"
                    :day="{absence: {type: AbsenceType.Timeoff} as Absence}"
                ></Day>
                <Day
                    @click:date="(d) => showModalDate = d.dateId"
                    :calendarDateSingleton="getCalendarDateSingleton('2025-01-04')"
                    :day="{demands: [{shiftType: ShiftType.Fd, jobsite}] as Demand[]}"
                ></Day>
                <Day
                    @click:date="(d) => showModalDate = d.dateId"
                    :calendarDateSingleton="getCalendarDateSingleton('2025-01-05')"
                    :day="{wishDemands: [{shiftType: ShiftType.Fd, jobsite}] as Demand[]}"
                ></Day>
                <Day
                    @click:date="(d) => showModalDate = d.dateId"
                    :calendarDateSingleton="getCalendarDateSingleton('2025-01-06')"
                    :propsLoading="true"
                ></Day>
                <CalendarDetail
                    v-model="showModalDate"
                ></CalendarDetail>
            </template>


            <!-- RICH RESPONSES -->

            <TimesheetList
                v-if="false"
                :init-data="1"
            />
            <JobsiteList
                v-if="false"
                :init-data="AInesJobsiteListType.Favorite"
            />
            <hr>
                <!--{listType:, selectable: true}-->
            <PlanningStatus
                v-if="false"
                init-data="2025-04-01"
            />
            <hr>
<hr>
            <PlanningStatus
                v-if="false"
                init-data="2025-03-01"
            />
            <MonthCalendar
                v-if="false"
                init-data="2024-09-01"
            />
            <WeekCalendar
                v-if="false"
                init-data="2024-10-01"
            />
            <DayCalendar
                v-if="false"
                init-data="2024-09-26"
            />
            <RegisterBookingSummary
                v-if="false"
                :init-data="{jobsite:'Station 3', shift: ShiftType.Fd, dates: ['2024-10-01']}"
            />
            <JobsiteSearch
                v-if="false"
                init-data="WMK-S4"
            />

            <InitCandidatePromotion v-if="false" :useLastChatMessageAsIntroMessage="false" :open-modal-immediately="false"/>

            <AutopilotJobsitePreferences v-if="false" init-data="2025-01-01" />


            <CandidateMissingJobsiteType v-if="false" :init-data="'1:1 Betreuung'"/>
            <DemandList v-if="true" :init-data="[333071, 333072, 333073, 333074, 333075]"/>



            <!-- DETAIL MODALS  -->

            <Modal v-if=false v-model="showModal" title="Detail" class="no-padding-left no-padding-right">
                <AvailabilityAbsenceEditor
                    v-if="false"
                    :availability="undefined"
                    :absence="undefined"
                    :date="undefined"
                ></AvailabilityAbsenceEditor>
                <CalendarDetail
                    v-if="false"
                    :date-id="undefined"
                ></CalendarDetail>
                <RegisterBookingSummary
                    v-if="false"
                    :init-data="{jobsiteSearchString:'Station 3', dates:['2024-11-10'], shift:ShiftType.Fd}"
                ></RegisterBookingSummary>

            </Modal>


            <!-- PROCESS MODALS  -->


<!--            <Modal v-if=false v-model="showModal" title="Prozess">-->
<!--                <RegisterBookingTopSheet-->
<!--                    v-if="false"-->
<!--                    :init-data="undefined"-->
<!--                ></RegisterBookingTopSheet>-->
<!--            </Modal>-->

            <StartPlanningMonth
                v-if="false"
                :month="'2025-04-01'"
                v-model="showModal"
            />


            <PlanningMonthSettingsModal
                v-if="false"
                :month="'2025-03-01'"
                v-model="showModal"
            />

            <CalendarModal
                v-if="false"
                v-model="showModal"
                initial-date="2024-09-01"
            ></CalendarModal>

            <JobsiteDetails
                v-if="false"
                v-model="jobsiteId"
            ></JobsiteDetails>

            <JobsiteInfo
                v-if="false"
                :init-data="jobsiteId.toString()"
                :render-shifts="false"
                expandable
                />

            <JobsiteInfo
                v-if="false"
                :init-data="jobsiteId.toString()"
            />

            <JobsiteSelectorModal
                v-if="false"
                v-model="showModal"
            />

            <RegisterBookingModal
                v-if="false"
                v-model="showModal"
            />
        </IonContent>
    </IonPage>
</template>

<script setup lang="ts">
import {IonContent, IonPage} from '@ionic/vue';
import RegisterBookingSummary from "@/views/Chat/RichResponses/RegisterBookingSummary.vue";
import {
    Absence,
    AbsenceType,
    AInesJobsiteListType,
    Booking,
    Demand,
    Jobsite,
    ShiftType
} from "@/graphql/generated/graphql";
import JobsiteList from "@/views/Chat/RichResponses/JobsiteList.vue";
import PlanningStatus from "@/views/Chat/RichResponses/PlanningStatus.vue";
import WeekCalendar from "@/views/Chat/RichResponses/WeekCalendar.vue";
import DayCalendar from "@/views/Chat/RichResponses/DayCalendar.vue";
import MonthCalendar from "@/views/Chat/RichResponses/MonthCalendar.vue";
import Modal from "@/components/Modal.vue";
import {Ref, ref} from "vue";
import JobsiteSearch from "@/views/Chat/RichResponses/JobsiteSearch.vue";
import TimesheetList from "@/views/Chat/RichResponses/TimesheetList.vue";
import JobsiteDetails from "@/views/JobsiteDetails.vue";
import AvailabilityAbsenceEditor from "@/components/AvailabilityAbsenceEditor.vue";
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import StartPlanningMonth from "@/views/components/Planning/StartPlanningMonth.vue";
import CalendarModal from "@/views/Calendar/CalendarModal.vue";
import InitCandidatePromotion from "@/views/Chat/RichResponses/InitCandidatePromotion.vue";
import AutopilotJobsitePreferences from "@/views/Chat/RichResponses/AutopilotJobsitePreferences.vue";
import CandidateMissingJobsiteType from "@/views/Chat/RichResponses/CandidateMissingJobsiteType.vue";
import DemandList from "@/views/Chat/RichResponses/DemandList.vue";
import JobsiteInfo from "@/views/Chat/RichResponses/JobsiteInfo.vue";
import Day from "@/views/Calendar/Day.vue";
import {getCalendarDateSingleton} from "@/helper/calendar/calendarDateSingleton";
import PlanningMonthSettingsModal from "@/views/components/Planning/PlanningMonthSettingsModal.vue";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import JobsiteSelectorModal from "@/views/components/JobsiteSelectorModal.vue";
import RegisterBookingModal from "@/views/components/RegisterBookingModal.vue";


const showModal: Ref<boolean> = ref(true);
const showModalDate: Ref<kapitelDateString|undefined> = ref('2025-01-01');
const jobsiteId = ref(6)

const jobsite = {name: 'Stationsbezeichnung 1', abbreviation: 'Station 1', client: { name : 'Kundenbezeichnung', abbreviation: 'K'}} as Jobsite
</script>

<style lang="scss" >
</style>
