<template>
    <ion-content class="no-padding own-content-calendar">
        <IonProgressBar type="indeterminate" v-if="isFetchingData" style="position: fixed"/>

        <div class="calendar-header">
            <MonthVue :month="currentVisibleMonth" :data="monthMetaDataMap[currentVisibleMonth]" />
            <ion-buttons>
                <ion-button
                    color="secondary"
                    fill="clear"
                    @click="showSubscriptionsModal = true"
                    v-if="false /*useAppState().isEmployeeOrCandidate('candidate') && useCandidateStore().isPromoted*/"
                >
                    <ion-icon slot="icon-only" :src="notificationsOutline" />
                </ion-button>
            </ion-buttons>
        </div>

        <div class="calendar">
            <MNSTR
                v-if="viewInitialized"
                ref="mnstr"
                :elements="currentMNSTRListElements"
                :initialScrollToElement="initialMNSTRScrollToElement"
                @renderfirstelement="onListRenderFirstElement"
                @renderlastelement="onListRenderLastElement"
                @firstinboundselementchanged="onListFirstInBoundsElementChanged"
                class="calendar-list"
                observe-cell-bounds
                keep-scroll-state-on-update
                v-on:touchmove="(e)=>{e.stopPropagation()}"
            >
                <template v-slot="{ element }">
                    <MonthVue
                        v-if="element.type === 'month'"
                        :month="element.month"
                        :data="monthMetaDataMap[element.month]"
                        :key="element.type + element.month"
                    />
                    <DayVue
                        v-else
                        :calendarDateSingleton="element"
                        :day="getDayForDate(element)"
                        :availabilityShiftDefaults="availabilityShiftDefaults"
                        :propsLoading="isPropsLoadingFor(element)"
                        @set:availability="(availability: Availability) => onSetAvailability(availability, true)"
                        @set:absence="(absence: Absence) => onSetAbsence(absence, true)"
                        @set:wish-demand="onRemoveWishDemand"
                        @click:date="(d) => {showDetailModalDate=d.dateId; showDetailModalExpectedContent=d.expectedContent}"
                        :key="element.type + element.date"
                    />
                </template>
            </MNSTR>
        </div>
    </ion-content>
    <CalendarDetail
        v-model="showDetailModalDate"
        :expected-content="showDetailModalExpectedContent"
        @set:availability="onSetAvailability"
        @set:absence="onSetAbsence"
    ></CalendarDetail>
    <SubscriptionsModal
        v-model="showSubscriptionsModal"
    />
</template>

<script setup lang="ts">
import {computed, defineExpose, PropType, ref, Ref, VNodeRef} from 'vue';
import {notificationsOutline} from "ionicons/icons";
import date from '@/helper/datetime/date';
import MNSTR from '@/libs/mnstr/Vmnstr.vue';
import {useGlobalEmitter} from '@/helper/emitter';
import {useEmployeeStore} from '@/store/employee';
import {useTimesheetStore} from '@/store/timesheet';
import {fetchDayForDate, fetchDaysForDateRange,} from '@/helper/day';
import {fetchAvailabilityPlanningDates} from '@/helper/availabilityPlanningDates';
import {fetchCurrentAutopilotStatus, getAutopilotStatusSummary} from '@/helper/autopilotStatus';
import DayVue from '@/views/Calendar/Day.vue';
import MonthVue from '@/views/Calendar/Month.vue';
import {fetchAvailabilityShiftDefaults} from '@/helper/availabilityShiftDefault';
import {getAvailabilitySummary, setAvailability} from '@/helper/availability';
import {getAbsenceSummary, setAbsence} from '@/helper/absence';
import {getBookingSummary} from '@/helper/booking';
import {
    CalendarDateSingleton,
    CalendarMonthSingleton,
    getCalendarDatesSingleton,
} from '@/helper/calendar/calendarDateSingleton';
import type {
    Absence,
    AutopilotStatus,
    Availability,
    AvailabilityPlanningDates,
    Booking,
    Day as DayObject,
} from '@/graphql/generated/graphql';
import {kapitelDateString} from '@/graphql/kapitelTypes';
import {IonButton, IonButtons, IonContent, IonIcon, IonProgressBar} from '@ionic/vue'
import CalendarDetail, {ExpectedContent} from "@/views/CalendarDetail/CalendarDetail.vue";
import SubscriptionsModal from "./SubscriptionsModal.vue";
import datetime from "@/helper/datetime/datetime";
import {useAppState} from "@/helper/appState";

const props = defineProps({
    initialDate: {
        required : false,
        type: String as PropType<kapitelDateString>,
        default: () => date.getToday()
    },
    deferInit: {
        required: false,
        type: Boolean,
        default: false
    }
})

const showDetailModalDate: Ref<kapitelDateString|undefined> = ref(undefined)
const showDetailModalExpectedContent: Ref<ExpectedContent> = ref(undefined)
const showSubscriptionsModal: Ref<boolean> = ref(false)

const employeeStore = useEmployeeStore();
const timesheetStore = useTimesheetStore();

type mnstrListElement = CalendarDateSingleton | CalendarMonthSingleton;
type DayDateMap = { [key: kapitelDateString]: DayObject };

const availabilityShiftDefaults = ref();

const mnstr : Ref<VNodeRef | undefined> = ref(undefined); // Initialize with undefined

const viewInitialized = ref(false)

const isFetchingData = ref(false);
const fetchedMonths = ref<Array<string>>([]);
const dayDataMap = ref<DayDateMap>({});
const monthMetaDataMap = ref({});
const planningDates = ref();
const autoPilotStatus = ref<Array<AutopilotStatus> | undefined>();

const calendarMin = ref(date.subMonths(date.startOfMonth(props.initialDate), 1));
const calendarMax = ref(date.endOfMonth(date.addMonths(calendarMin.value, 2)));
const currentVisibleMonth = ref<kapitelDateString>(date.startOfMonth(props.initialDate));
const employeeContractStart = ref<kapitelDateString>();

/**
 * These events are triggered from the outside.
 * They will invalidate the data of a specific date.
 */
useGlobalEmitter().on('day:mutated', (date: kapitelDateString) => setDateContentDirty(date));

const onSetAvailability = async (availability: Availability, shouldSave: boolean) => {
    if(shouldSave){
        await setAvailability(availability);
    }
    dayDataMap.value[availability.date] = await fetchDayForDate(availability.date);
};

const onSetAbsence = async (absence: Absence, shouldSave: boolean) => {
    if(shouldSave){
        await setAbsence(absence);
    }
    dayDataMap.value[absence.date] = await fetchDayForDate(absence.date);
};

const onRemoveWishDemand = async (demand: Demand) => {
    const date = datetime.convertDateTime2Date(demand.begin)
    dayDataMap.value[date] = await fetchDayForDate(date);
};

/**
 * Reinit the view. Scroll to new date if param is set.
 */
const reinit = () => {
    if (props.initialDate) {
        const scrollToDate = props.initialDate;

        calendarMin.value = date.subMonths(date.startOfMonth(scrollToDate), 1);
        calendarMax.value = date.endOfMonth(date.addMonths(calendarMin.value, 2));

        // if (mnstr.value && initialMNSTRScrollToElement.value) {
        //     mnstr.value.scrollToElement(initialMNSTRScrollToElement.value);
        // }
    }

    monthMetaDataMap.value = {};
    planningDates.value = undefined;
    fetchedMonths.value = [];
    employeeContractStart.value = undefined;

    fetchDataIfNeeded();

    if (!viewInitialized.value) {
        viewInitialized.value = true;
    }
};

/**
 * Get the mnstr list element of type "date" by its date.
 * @param dateStr
 */
const getMNSTRListElementByDate = (dateStr: kapitelDateString): mnstrListElement => {
    return currentMNSTRListElements.value.find(
        (element) => element.id === 'date_' + dateStr
    );
};


/**
 * The renderset for the mnstr list,
 * computed by the calendarMin and calendarMax dates.
 */
const currentMNSTRListElements = computed(() => {
    console.log(`MNSTR list bounds from ${calendarMin.value} to ${calendarMax.value}`);
    return getCalendarDatesSingleton(calendarMin.value, calendarMax.value, true);
});

const initialMNSTRScrollToElement = computed(() => {

    const elem = getMNSTRListElementByDate(props.initialDate)
    console.warn("initialscrollto", props.initialDate, elem)
    return elem
})

/**
 * A computed representation of the current list elements' months
 * in the format YYYY-MM-DD[].
 */
const currentRenderedMonths = computed(() => {
    return currentMNSTRListElements.value.reduce((acc: Array<string>, element: mnstrListElement) => {
        if (element.type === 'month' && !acc.includes(element.month)) {
            acc.push(element.month);
        }
        return acc;
    }, []);
});

/**
 * Triggered when mnstr renders the very first element
 * of its current list elements.
 * Will prepend 1 month and remove the last one,
 * then fetch data if needed.
 */
const onListRenderFirstElement = () => {
    if (!viewInitialized.value) {
        return
    }

    if (!mnstr.value?.isInitialized()) {
        return;
    }

    if (
        employeeContractStart.value &&
        date.differenceInMonths(employeeContractStart.value, calendarMin.value) >= 0
    ) {
        return;
    }

    calendarMin.value = date.subMonths(calendarMin.value, 1);
    calendarMax.value = date.endOfMonth(date.subDays(calendarMax.value, 42));

    fetchDataIfNeeded();
};

/**
 * Triggered when mnstr renders the very last element
 * of its current list elements.
 * Will append 1 month and remove the first one,
 * then fetch data if needed.
 */
const onListRenderLastElement = () => {
    if (!viewInitialized.value) {
        return
    }

    if (!mnstr.value?.isInitialized()) {
        return;
    }

    calendarMin.value = date.addMonths(calendarMin.value, 1);
    calendarMax.value = date.endOfMonth(date.addDays(calendarMax.value, 7));

    fetchDataIfNeeded();
};

/**
 * Triggered when the first visible element of mnstr has changed.
 * Used to update the month indicator in the page header.
 * @param element
 */
const onListFirstInBoundsElementChanged = (element: mnstrListElement) => {
    if (!viewInitialized.value) {
        return
    }
    if (element.type === 'date') {
        currentVisibleMonth.value = date.startOfMonth(element.date);
    }
    if (element.type === 'month') {
        const previousMonth = date.subMonths(element.month, 1);
        if (employeeContractStart.value && date.isBefore(previousMonth, employeeContractStart.value)) {
            return;
        }
        if (date.isBefore(previousMonth, calendarMin.value)) {
            return
        }
        currentVisibleMonth.value = previousMonth;
    }
};

/**
 * Fetch day data for all necessary months
 * and store it in dayDataMap for quick access.
 * Remembers fetched months so they only get fetched once.
 * This will be invalidated when the view gets entered again.
 */
const fetchDataIfNeeded = (refresh = false) => {
    const monthsToFetch = currentRenderedMonths.value.filter(
        (month) => !fetchedMonths.value.includes(month) || refresh
    );
    isFetchingData.value = true;

    const fetchPromises: Array<Promise<void | AvailabilityPlanningDates>> = monthsToFetch.map(
        (month) => {
            const begin = month;
            const end = date.endOfMonth(begin);

            console.log(`Fetching days for month ${month} (${begin} - ${end})`);

            return fetchDaysForDateRange(begin, end).then((days: Array<DayObject>) => {
                days.forEach((day) => (dayDataMap.value[day.date] = day));
                fetchedMonths.value.push(month);
            });
        }
    );

    if (useAppState().isEmployeeOrCandidate('employee')) {
        if (!planningDates.value || refresh) {
            fetchPromises.push(
                fetchAvailabilityPlanningDates().then((res) => (planningDates.value = res))
            );
        }

        if (!autoPilotStatus.value || refresh) {
            fetchPromises.push(
                fetchCurrentAutopilotStatus().then((res) => (autoPilotStatus.value = res))
            );
        }

        if (!employeeContractStart.value) {
            fetchPromises.push(
                employeeStore.getEmployee().then((res) => {
                    employeeContractStart.value = res.firstContract.validFrom;
                })
            );
        }

        if (!availabilityShiftDefaults.value) {
            fetchPromises.push(
                fetchAvailabilityShiftDefaults().then((res) => availabilityShiftDefaults.value = res)
            )
        }
    }

    Promise.all(fetchPromises).finally(() => {
        isFetchingData.value = false;
        updateMetaDataForMonthsIfNeeded(monthsToFetch, refresh);
    });
};

/**
 * Invalidates the loaded day data for a single date.
 * This will result in showing a skeleton for
 * this single date.
 * @param date
 */
const setDateContentDirty = async (date: kapitelDateString) => {
    delete dayDataMap.value[date];
    dayDataMap.value[date] = await fetchDayForDate(date)
};

/**
 * As the title suggests - this will return the
 * day data for a single date.
 * @param date
 */
const getDayForDate = (date: CalendarDateSingleton): DayObject | undefined => {
    return dayDataMap.value[date.date];
};

/**
 * This will check if there is day data loaded for
 * a single date.
 * @param date
 */
const isPropsLoadingFor = (date: CalendarDateSingleton) => !dayDataMap.value[date.date];

/**
 * Calculates the meta data for each currently displayed month.
 * Is triggered after fetching all dependency data.
 * Calculated data is cached until the view is leaved.
 */
const updateMetaDataForMonthsIfNeeded = (months: Array<kapitelDateString>, refresh = false) => {
    (months || []).forEach((month) => {
        if (monthMetaDataMap.value[month] && !refresh) {
            return;
        }

        console.time('calculate meta data for month ' + month);

        const days = Object.values(dayDataMap.value).filter((dayData: DayObject) =>
            date.isSameMonth(dayData.date, month)
        );
        const currentAutopilotStatus = autoPilotStatus.value?.find(
            (status) => status.month === month
        );

        const statusSummary = currentAutopilotStatus ? getAutopilotStatusSummary(currentAutopilotStatus) : undefined

        const isBooking = currentAutopilotStatus !== undefined;
        const isPlanning = planningDates.value
            ? date.isAfter(month, planningDates.value.planningThreshold)
            : false;
        const targetBookings = currentAutopilotStatus?.bookingTarget
        const jobsiteCount = statusSummary?.jobsiteSummary.count

        const bookings = days
            .map((d) => d.booking)
            .filter((b): b is Booking => b !== null && b !== undefined);
        const numBookings = getBookingSummary(bookings).shifts;

        const availabilities = days
            .map((d) => d.availability)
            .filter((a): a is Availability => a !== null && a !== undefined);
        const numAvailabilities = getAvailabilitySummary(availabilities).count;

        const absences = days
            .map((d) => d.absence)
            .filter((a): a is Absence => a !== null && a !== undefined);
        const numAbsences = getAbsenceSummary(absences).count;

        const numUntrackedPastBookings = timesheetStore.getToDosByMonth(month).length;
        const isTimeTracking = numUntrackedPastBookings > 0;

        const isContractStart = month === employeeContractStart.value;

        monthMetaDataMap.value[month] = {
            month: month,
            isTimeTracking: isTimeTracking,
            isBooking: isBooking,
            isPlanning: isPlanning,
            isContractStart,
            isPast: date.isBefore(month, date.startOfMonth(date.getToday())),
            numBookings: numBookings,
            numUntrackedPastBookings: numUntrackedPastBookings,
            numAvailabilities: numAvailabilities,
            numAbsences: numAbsences,
            targetBookings,
            jobsiteCount
        };

        console.timeEnd('calculate meta data for month ' + month);
    });
};

useGlobalEmitter().on("autopilotPreferences:mutated", (month) => fetchDataIfNeeded(true) ) //updateMetaDataForMonthsIfNeeded([month], true))
useGlobalEmitter().on('availabilityPlanningMonth:mutated', (month) => fetchDataIfNeeded(true) ) //updateMetaDataForMonthsIfNeeded([month, date.subMonths(month, 1)], true))

defineExpose({
    reinit
});
if (!props.deferInit) {
    reinit()
}
</script>

<style lang="scss" scoped>
// TWINKIIIII
.own-content-calendar {
    height: 100%;
}
.own-content-calendar::part(scroll) {
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
    height: 100%
}

.calendar-header {
    flex: 0 0 auto;
    display: flex;
    padding-right: var(--custom-spacing-app-content-padding-horizontal);

    > *:first-child {
        flex: 1;
    }

    > *:not(:first-child) {
        flex: 0 0 auto;
    }

    ion-buttons {
        align-items: flex-start;

        ion-button {
            margin-top: 2.3em;
        }
    }
}

.calendar {
    flex: 1 1 100%;
    position: relative;
    overflow: hidden;

    .calendar-list {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;

        .divider-month {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 6rem;
            color: var(--ion-color-dark);
            font-size: var(--custom-font-size-larger);
            font-weight: var(--custom-font-weight-bold);
            padding-left: var(--custom-spacing-app-content-padding-horizontal);
            padding-right: var(--custom-spacing-app-content-padding-horizontal);

            .planning-threshold {
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 2rem;
                text-align: center;
            }
        }

        .divider-planning-threshold {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6rem;
        }
    }
}
</style>
