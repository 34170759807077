<script setup lang="ts">
import {computed, ref, Ref} from "vue";
import {IonActionSheet, IonButton, IonItem, IonLabel, IonList} from "@ionic/vue";
import datetime from "@/helper/datetime/datetime";
import AvailabilityConstraintEditorModal from './AvailabilityConstraintEditorModal.vue'
import {AvailabilityTimeConstraint, Jobsite} from "@/graphql/generated/graphql";
import JobsiteSelectorModal from "@/views/components/JobsiteSelectorModal.vue";
import {getEmployeeJobsiteCategorySegments, JobsiteCategorySegment} from "@/helper/jobsite";

const props = defineProps({
    date: String,
    availability: Object,
});

const emit = defineEmits(["update:availability"]);

const isAddConstraintModalOpen = ref<boolean>(false);
const isRemoveConstraintModalOpen = ref<boolean>(false);
const selectedTimeConstraint = ref<AvailabilityTimeConstraint|undefined>(undefined)
const selectedJobsiteConstraint = ref<Jobsite|undefined>(undefined)
const isTimeConstraintModalOpen = ref(false)
const isJobsiteConstraintModalOpen = ref(false)

const sortedConstraints = computed(() => {
    let sortFn;
    if (props.availability) {
        sortFn = (
            a: AvailabilityTimeConstraint,
            b: AvailabilityTimeConstraint,
        ) => {
            return datetime.cmpKapitelDateTime(a.begin, b.begin);
        };

        return props.availability?.constraints.sort(sortFn);
    }
    return [];
});


const actionSheetAddConstraintButtons = [
    {
        text: "Uhrzeit einschränken",        
        data: {
            action: "constrainTimes",
        },
    },
    {
        text: "Station einschränken",        
        data: {
            action: "excludeJobsite",
        },
    },
    {
        text: "Abbrechen",
        role: "cancel",
        data: {
            action: "cancel",
        },
    },
];


const actionSheetRemoveButtons = [
    {
        text: "Einschränkung entfernen",
        role: 'destructive',
        data: {
            action: "removeConstraint",
        },
    },
    {
        text: "Abbrechen",
        role: "cancel",
        data: {
            action: "cancel",
        },
    },
];

const onActionModalAddConstraintDismiss = async (event) => {
    const data = event.detail.data;

    if(!data?.action){
        isAddConstraintModalOpen.value = false
        return
    }

    switch (data.action) {
        case "constrainTimes":
            isTimeConstraintModalOpen.value = true
            break;

        case "excludeJobsite":
            isJobsiteConstraintModalOpen.value = true
            break;
    }

    isAddConstraintModalOpen.value = false
};

const onActionModalRemoveConstraintDismiss = async (event) => {
    const data = event.detail.data;

    if(!data?.action){
        isRemoveConstraintModalOpen.value = false
        return
    }

    const newAvailability = JSON.parse(JSON.stringify(props.availability))
    let somethingChanged = false

    switch (data.action) {
        case "removeConstraint":
            if(selectedTimeConstraint.value){
                const index = props.availability.constraints.indexOf(selectedTimeConstraint.value)
                if(index>=0){
                    newAvailability.constraints.splice(index, 1)
                    somethingChanged = true
                }
            }else if(selectedJobsiteConstraint.value){
                const index = props.availability.excludedJobsites.indexOf(selectedJobsiteConstraint.value)
                if(index>=0){
                    newAvailability.excludedJobsites.splice(index, 1)
                    somethingChanged = true
                }
            }
            break;
    }
    if(somethingChanged){
        emit("update:availability", newAvailability)
    }

    isRemoveConstraintModalOpen.value = false
    selectedTimeConstraint.value = undefined
    selectedJobsiteConstraint.value = undefined
};

const onAddTimeConstraint = (timeConstraint: AvailabilityTimeConstraint) => {
    const newAvailability = JSON.parse(JSON.stringify(props.availability))
    newAvailability.constraints.push(timeConstraint)
    emit("update:availability", newAvailability)
    isAddConstraintModalOpen.value = false
}

const onJobsiteConstraintSelect = (jobsite: Jobsite)=> {
    const newAvailability = JSON.parse(JSON.stringify(props.availability))
    if(!newAvailability.excludedJobsites){
        newAvailability.excludedJobsites = []
    }
    newAvailability.excludedJobsites.push(jobsite)
    emit("update:availability", newAvailability)
    isAddConstraintModalOpen.value = false
}

const onClickExistingTimeConstraint = (constraint: AvailabilityTimeConstraint) => {
    selectedTimeConstraint.value = constraint
    isRemoveConstraintModalOpen.value = true
}

const onClickExistingJobsiteConstraint = (jobsite: Jobsite) => {
    selectedJobsiteConstraint.value = jobsite
    isRemoveConstraintModalOpen.value = true
}

const employeeJobsiteSegments : Ref<JobsiteCategorySegment[]> = ref([])
getEmployeeJobsiteCategorySegments() 
    .then(segments => employeeJobsiteSegments.value = segments)

</script>
<template>
    <ion-list class="custom-ion-card-content-item-full-width" lines="none">
        <ion-item
            class="constraint-item"
            v-for="constraint in sortedConstraints"
            detail="true"
            detailIcon="/icons/caret-right.svg"
            @click="onClickExistingTimeConstraint(constraint)"
        >
            <ion-label>
                <p class="text-dark">
                    nicht
                    {{
                        datetime.formatKapitelDateTime(constraint.begin, "T") ||
                        "egal"
                    }}
                    {{ constraint.begin && !constraint.until ? "Uhr" : "" }}
                    -
                    {{
                        datetime.formatKapitelDateTime(constraint.until, "T") ||
                        "egal"
                    }}
                    {{ constraint.until ? "Uhr" : "" }}
                    <span v-if="constraint.comment">
                        ({{ constraint.comment }})
                    </span>
                </p>
            </ion-label>
        </ion-item>

        <ion-item
            class="constraint-item"
            v-for="jobsite in availability?.excludedJobsites"
            :detail="true"
            detailIcon="/icons/caret-right.svg"
            @click="onClickExistingJobsiteConstraint(jobsite)"
        >
            <ion-label>
                <p class="text-dark">nicht auf {{ jobsite.abbreviation }}</p>
            </ion-label>
        </ion-item>
        <ion-button
            style="margin-top: 5px;"
            expand="block"
            color="light"
            @click="isAddConstraintModalOpen = true"
            >Einschränkung hinzufügen</ion-button
        >
    </ion-list>

    <ion-action-sheet
        :is-open="isAddConstraintModalOpen"        
        :buttons="actionSheetAddConstraintButtons"
        @didDismiss="onActionModalAddConstraintDismiss"
    ></ion-action-sheet>

    <ion-action-sheet
        :is-open="isRemoveConstraintModalOpen"        
        :buttons="actionSheetRemoveButtons"
        @didDismiss="onActionModalRemoveConstraintDismiss"
    ></ion-action-sheet>

    <AvailabilityConstraintEditorModal
        v-model="isTimeConstraintModalOpen"
        :containing-availability="availability || undefined"
        v-on:add:timeConstraint="onAddTimeConstraint"
    />

    <JobsiteSelectorModal
        v-model="isJobsiteConstraintModalOpen"
        :alreadySelectedJobsites="props.availability?.excludedJobsites || []"
        :quick-select-jobsite-category-segments="employeeJobsiteSegments"
        @select="onJobsiteConstraintSelect"
    />

</template>
<style scoped>
    .constraint-item {
        border: 1px solid #ddd;
        border-radius: 10px;
        padding-left: 12px;
        margin-bottom: 0.5em;
    }
</style>
