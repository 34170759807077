
<script setup lang="ts">

import {formatMonthFromKapitelDate, getYearFromKapitelDate} from "@/helper/datetime/date"
import {PropType} from "vue"
import {kapitelDateString} from '@/graphql/kapitelTypes';
import {IonButton} from "@ionic/vue";
import Modal from "@/components/Modal.vue";
import PlanningStatusAndPreferences from "@/views/components/Planning/PlanningStatusAndPreferences.vue";

const showMe = defineModel({type: Boolean})
const props = defineProps({
    month: {
        type: String as PropType<kapitelDateString>,
        required: true
    }
})
</script>


<template>
<Modal v-model="showMe" :title="`${formatMonthFromKapitelDate(props.month)} ${getYearFromKapitelDate(props.month)}`">
    <template v-slot:default>
        <PlanningStatusAndPreferences :month="props.month" :show-calendar="false" :focus-mode="'settings'"/>
    </template>
    <template v-slot:actions>
        <ion-button
            color="primary"
            expand="block"
            @click="showMe = false"
        >
            Ok
        </ion-button>
    </template>
</Modal>
</template>
