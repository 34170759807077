<script setup lang="ts">
import {Absence, Booking, Day, ShiftType} from "@/graphql/generated/graphql";
import {actionSheetController, IonButton, IonDatetime,} from "@ionic/vue";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {computed, PropType, Ref, ref, watch} from "vue";
import {DataLoader} from "@/helper/dataLoader";
import Modal from "@/components/Modal.vue";
import date, {validateKapitelDate} from "@/helper/datetime/date";
import {shiftDate} from "@/helper/registerBooking"
import {getLabelForShift, orderedShiftTypes} from "@/helper/shiftTypes"
import {fetchDaysForDateRange, yieldEffectiveAbsences, yieldEffectiveBookings,} from "@/helper/day";
import {pluralize} from "../../helper/amountFormatter";

const props = defineProps({
    modelValue: Boolean,
    startDate: String as PropType<kapitelDateString>,
    preselectedDates: Array as PropType <kapitelDateString[]>,
});

const emit = defineEmits(["update:modelValue", "add:shifts"]);

const datetime : Ref<undefined | {$el:HTMLIonDatetimeElement}> = ref()
const resetCalendarView =  () => {
    if (datetime.value && props.startDate) {
        console.error("unsupported yet because datetime not scrollable")
    }
}
watch(
    () => props.startDate,
    resetCalendarView
)


const isOpen = ref(false);
watch(
    () => props.modelValue,
    (value) => (isOpen.value = value),
);
watch(
    () => isOpen.value,
    (value) => {
        if (!value) {
            selectedDates.value = [];
            dateTimePickerValue.value = undefined;
        }
        emit("update:modelValue", value)
    },
);

const dateTimePickerValue : Ref<Array<kapitelDateString> | undefined> = ref(undefined);

const referenceDate = date.getToday();
const firstSelectableDate = date.startOfMonth(referenceDate)
const lastSelectableDate = date.endOfMonth(date.addMonths(referenceDate, 6))


const dayItems = ref<Day[]>([]);

const startShift = ShiftType.Fd
const shiftTypeToAdd = ref<ShiftType>(startShift)

const selectedDates : Ref<Array<kapitelDateString>>  = ref([])
watch(
    () => props.preselectedDates,
    (value) => {
        if (value) {
            const ds = value.filter(d => {
                const blockingAbsence = yieldEffectiveAbsences(dayItems.value).find((absence: Absence) => absence.date === d)
                const blockingBooking = yieldEffectiveBookings(dayItems.value).find((booking: Booking) => booking.date === d)
                return !blockingAbsence && !blockingBooking
            })
            ds.forEach(d => {
                if (!selectedDates.value.includes(d)) {
                    selectedDates.value.push(d)
                }
                dateTimePickerValue.value = selectedDates.value
            })
        }
    },
    {immediate: true}
)

const highlightedDates = computed(() => {
    const result : Array<{date: kapitelDateString, textColor: string}>= []

    yieldEffectiveAbsences(dayItems.value).forEach((absence: Absence)=>{
        result.push({
            date: absence.date,
            textColor: 'var(--ion-color-grey-tint-1)',
        },)
    })

    yieldEffectiveBookings(dayItems.value).forEach((booking: Booking)=>{
        result.push({
            date: booking.date,
            textColor: 'var(--ion-color-grey-tint-1)',
        },)
    })

    return result

});

const onChange = async () => {
    const newSelectedDate = dateTimePickerValue.value?.find((date: kapitelDateString) => !selectedDates.value.includes(date))

    if (newSelectedDate) {

        // ensure date picker selection is valid kapitelDate
        try {
            validateKapitelDate(newSelectedDate)
        } catch (error) {
            dateTimePickerValue.value = selectedDates.value
            return;
        }

        const blockingAbsence = yieldEffectiveAbsences(dayItems.value).find((absence: Absence) => absence.date === newSelectedDate)
        const blockingBooking = yieldEffectiveBookings(dayItems.value).find((booking: Booking) => booking.date === newSelectedDate)

        if (blockingAbsence || blockingBooking) {
            const actionSheetHeader = blockingAbsence
                ? `An diesem Tag ist eine Abwesenheit eingetragen.`
                : `An diesem Tag hast du bereits einen Dienst eingetragen.`

            const actionSheetButtons = [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                    data: {
                        action: 'cancel',
                    },
                },
            ]

            if (blockingAbsence) {
                actionSheetButtons.push({
                    text: 'Dienst trotzdem eintragen',
                    role: 'destructive',
                    data: {
                        action: 'delete',
                    },
                })
            }

            const actionSheet = await actionSheetController.create({
                header: actionSheetHeader,
                buttons: actionSheetButtons,
            })

            actionSheet.onWillDismiss().then((event) => {
                if (!event.data || event.data.action === 'cancel') {
                    dateTimePickerValue.value = selectedDates.value
                }

                selectedDates.value = dateTimePickerValue.value || []
            })

            await actionSheet.present();
        }
        else {
            selectedDates.value = dateTimePickerValue.value || []
        }
    }
    else {
        selectedDates.value = dateTimePickerValue.value || []
    }
}

const submit = ()=> {
    const shiftDates: shiftDate[] = selectedDates.value.sort().map(date => {
        return {
            type: shiftTypeToAdd.value,
            date: date
        }
    })

    emit("add:shifts", shiftDates)
}


const loader = new DataLoader(async () => {
    dayItems.value = await fetchDaysForDateRange(
        firstSelectableDate,
        lastSelectableDate
    );
});

watch(isOpen, (v) => {if (v) {loader.load()}})
loader.load();
</script>

<template>
    <Modal
        v-model="isOpen"
        title="Schichten hinzufügen"
        class="add-shift-modal"
        @view:didPresent="resetCalendarView"
    >
        <div class="shift-toggler">
            <ion-button
                v-for="shift in orderedShiftTypes"
                :key="shift"
                :color="shiftTypeToAdd === shift ? 'secondary' : 'light'"
                v-on:click="shiftTypeToAdd = shift as ShiftType"
            >
                {{ shift }}
            </ion-button>
        </div>
        <ion-datetime
            locale="de-DE"
            :first-day-of-week="1"
            presentation="date"
            :multiple="true"
            :highlighted-dates="highlightedDates"
            :min="firstSelectableDate"
            :max="lastSelectableDate"
            v-model="dateTimePickerValue"
            @ionChange="onChange"
            color="secondary"
            ref="datetime"
        />
            
        <ion-button
            :disabled="!selectedDates.length"
            @click="submit"
            expand="block"
            color="primary"
        >
            {{ pluralize(selectedDates.length, getLabelForShift(shiftTypeToAdd), getLabelForShift(shiftTypeToAdd) + 'e') }} hinzufügen
        </ion-button>
    </Modal>
</template>

<style lang="scss">
.add-shift-modal {
    .shift-toggler {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > * {
            flex: 0 1 22%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    ion-datetime {
        background-color: transparent;

        &::part(calendar-day) {
            font-size: var(--custom-font-size-regular);
        }

        
        &::part(calendar-day):focus {
            background-color: transparent;
            box-shadow: none;
        }

        &::part(calendar-day active) {
            &,
            &:focus {
                background-color: var(--ion-color-secondary);
                color: var(--ion-color-white);
                font-weight: var(--custom-font-weight-regular);
            }
            
        }
    }
}

</style>
