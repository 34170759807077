<template>
<div>
    <RichResponseListItem
        v-for="jobsite in jobsites"
        iconType="image"
        :icon="getImageForJobsite(jobsite)"
        :title="jobsite.abbreviation?.toString()"
        :text="jobsite.client?.abbreviation?.toString()"
        @update:modelValue="updateSelection(jobsite)"
        @click="onClick(jobsite)"
    />
    <RichResponseButton
        title="Stationsverwaltung"
        @click="jobsiteManageOpen = true"
    />
    <JobsiteManager v-model="jobsiteManageOpen"></JobsiteManager>
<!--        :selectable="props.initData.selectable"-->
    <JobsiteDetails v-model="jobsiteDetailFor"></JobsiteDetails>
</div>
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import {getImageForJobsite, getJobsitesByType} from '@/helper/jobsite'
import {Ref, ref} from "vue";
import {AInesJobsiteListType, Jobsite} from "@/graphql/generated/graphql";
import {RichResponseEmits} from './RichResponseComposable'
import RichResponseButton from "@/views/Chat/RichResponses/components/RichResponseButton.vue";
import JobsiteManager from "@/views/Settings/JobsiteManager.vue";
import JobsiteDetails from "@/views/JobsiteDetails.vue";

const jobsiteManageOpen = ref(false)

const props = defineProps<{
    initData: AInesJobsiteListType
}>()

const jobsites = ref<Jobsite[]>([])
const selectedJobsiteIds = ref<number[]>([])

const jobsiteDetailFor:Ref<number|undefined> = ref(undefined)

const emit = defineEmits(['details'])
const onClick = (jobsite: Jobsite) => {
    jobsiteDetailFor.value = jobsite.id
    // emit("details", {
    //     type: "Jobsite",
    //     initData: {
    //         jobsiteId: jobsite.id
    //     }
    // })
}

const updateSelection = (jobsite: Jobsite) => {
    if (isJobsiteSelected(jobsite)) {
        selectedJobsiteIds.value = selectedJobsiteIds.value.filter(id => id !== jobsite.id)
    }
    else {
        selectedJobsiteIds.value = [...selectedJobsiteIds.value, jobsite.id]
    }

    if (selectedJobsiteIds.value.length > 0) {
        emit(RichResponseEmits.BLOCKPROMPT)
    }
    else {
        emit(RichResponseEmits.UNBLOCKPROMPT)
    }
}

const isJobsiteSelected = (jobsite: Jobsite) => {
    return selectedJobsiteIds.value.includes(jobsite.id)
}

const fetchJobsites = (listType: AInesJobsiteListType) => {
    return getJobsitesByType(listType).then((res: Jobsite[]) => {
        jobsites.value = res
    })
}

fetchJobsites(props.initData)
</script>
