import {kapitelDateString} from "@/graphql/kapitelTypes";
import {DateTime as LuxonDateTime, Interval as LuxonInterval, WeekdayNumbers} from "luxon";


export const allEmployeeTimezone = "Europe/Berlin"
export const allEmployeeLocale = "de"
// YYYY-MM-DD
const dateRE = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/

export const validateKapitelDate = (dateString:kapitelDateString) => {
  if (!dateRE.test(dateString)) {
      throw new Error("malformed kapitelDateString")
  }
}

// private
const kapitelDateStringToLuxon = (dateString: kapitelDateString) : LuxonDateTime => {
  validateKapitelDate(dateString)
  return LuxonDateTime.fromISO(dateString, {zone: "utc"})
}

export const getToday = (): kapitelDateString => {
  return LuxonDateTime
      .now()
      .setZone(allEmployeeTimezone)
      .toFormat('yyyy-MM-dd')
}

export const formatWeekdayShortFromKapitelDate = (dateString: kapitelDateString, punctuate = true): string => {
    let abbr = formatKapitelDate(dateString, "EEE")
    if (!punctuate) {
        abbr = abbr.substring(0,2)
    }
    return abbr
}

export const formatWeekdayFromKapitelDate = (dateString: kapitelDateString): string => {
  return formatKapitelDate(dateString, "EEEE")
}

export const formatMonthFromKapitelDate = (dateString: kapitelDateString): string => {
  return formatKapitelDate(dateString, "LLLL")
}

export const getMonthFromKapitelDate = (dateString: kapitelDateString): number => {
  return Number(formatKapitelDate(dateString, "L"))
}

export const getDayOfMonthFromKapitelDate = (dateString: kapitelDateString): number => {
  return Number(formatKapitelDate(dateString, "d"))
}

export const getISODayOfWeekFromKapitelDate = (dateString: kapitelDateString): number => {
  return Number(formatKapitelDate(dateString, "c"))
}

export const formatISODayOfWeek = (dow : WeekdayNumbers, format: 'ccc' | 'cccc' | 'ccccc'): string => {
    return LuxonDateTime.local().set({weekday:dow}).toFormat(format, {locale: allEmployeeLocale})
}

export const getISOWeekNumberFromKapitelDate = (dateString: kapitelDateString): number => {
  return Number(formatKapitelDate(dateString, "WW"))
}

export const getYearFromKapitelDate = (dateString: kapitelDateString): number => {
  return Number(formatKapitelDate(dateString, "y"))
}

export const startOfMonth = (dateString: kapitelDateString): kapitelDateString => {
  const dateTime = kapitelDateStringToLuxon(dateString)
  return dateTime.startOf("month").toFormat('yyyy-MM-dd')
}

export const endOfMonth = (dateString: kapitelDateString): kapitelDateString => {
  const dateTime = kapitelDateStringToLuxon(dateString)
  return dateTime.endOf("month").toFormat('yyyy-MM-dd')
}

export const addMonths = (dateString: kapitelDateString, amount: number): kapitelDateString => {
  const dateTime = kapitelDateStringToLuxon(dateString)
  return dateTime.plus({months:amount}).toFormat('yyyy-MM-dd')
}

export const subMonths = (dateString: kapitelDateString, amount: number): kapitelDateString => {
  const dateTime = kapitelDateStringToLuxon(dateString)
  return dateTime.minus({months:amount}).toFormat('yyyy-MM-dd')
}


export const differenceInMonths = (string1: kapitelDateString, string2: kapitelDateString): number => {
  const dateTime1 = kapitelDateStringToLuxon(string1)
  const dateTime2 = kapitelDateStringToLuxon(string2)

  return dateTime1.diff(dateTime2 ).as("months")

}

export const startOfWeek = (dateString: kapitelDateString): kapitelDateString => {
  const dateTime = kapitelDateStringToLuxon(dateString)
  return dateTime.startOf("week").toFormat('yyyy-MM-dd')
}

export const endOfWeek = (dateString: kapitelDateString): kapitelDateString => {
  const dateTime = kapitelDateStringToLuxon(dateString)
  return dateTime.endOf("week").toFormat('yyyy-MM-dd')
}

export const addWeeks = (dateString: kapitelDateString, amount: number): kapitelDateString => {
    const dateTime = kapitelDateStringToLuxon(dateString)
    return dateTime.plus({weeks:amount}).toFormat('yyyy-MM-dd')
}

export const addDays = (dateString: kapitelDateString, amount: number): kapitelDateString => {
  const dateTime = kapitelDateStringToLuxon(dateString)
  return dateTime.plus({days:amount}).toFormat('yyyy-MM-dd')
}

export const subDays = (dateString: kapitelDateString, amount: number): kapitelDateString => {
  const dateTime = kapitelDateStringToLuxon(dateString)
  return dateTime.minus({days:amount}).toFormat('yyyy-MM-dd')
}

/* string1 is before string2 */
export const isBefore = (string1: kapitelDateString, string2: kapitelDateString): boolean =>{
  const date1 = kapitelDateStringToLuxon(string1)
  const date2 = kapitelDateStringToLuxon(string2)
  return date1.diff(date2, "days").days<0
}
/* string1 is after string2 */
export const isAfter = (string1: kapitelDateString, string2: kapitelDateString): boolean =>{
  const date1 = kapitelDateStringToLuxon(string1)
  const date2 = kapitelDateStringToLuxon(string2)
  return date1.diff(date2, "days").days>0
}
export const isSameDay = (string1: kapitelDateString, string2: kapitelDateString): boolean =>{
  const date1 = kapitelDateStringToLuxon(string1)
  const date2 = kapitelDateStringToLuxon(string2)
  return date1.diff(date2, "days").days==0
}
export const isSameMonth = (string1: kapitelDateString, string2: kapitelDateString): boolean =>{
  const date1 = kapitelDateStringToLuxon(string1)
  const date2 = kapitelDateStringToLuxon(string2)
  return Math.floor(date1.diff(date2, "months").months)==0
}
export const max = (string1: kapitelDateString, string2: kapitelDateString) : kapitelDateString => {
    return isAfter(string1, string2) ? string1 : string2
}
export const min = (string1: kapitelDateString, string2: kapitelDateString) : kapitelDateString => {
    return isBefore(string1, string2) ? string1 : string2
}

export const isFirstDayOfMonth = (dateString: kapitelDateString): boolean => {
  return getDayOfMonthFromKapitelDate(dateString) == 1
}

export const isLastDayOfMonth = (dateString: kapitelDateString): boolean => {
  return endOfMonth(dateString) == dateString
}

export const isMonday = (dateString: kapitelDateString): boolean => {
  return getISODayOfWeekFromKapitelDate(dateString) == 1
}

export const isToday = (dateString: kapitelDateString): boolean => {

    const todayDate = kapitelDateStringToLuxon(getToday())
    const queryDate = kapitelDateStringToLuxon(dateString)

    return queryDate==todayDate

}
export const isYesterday = (dateString: kapitelDateString): boolean => {

    const todayDate = kapitelDateStringToLuxon(getToday())
    const queryDate = kapitelDateStringToLuxon(dateString)

    return queryDate.plus({days: 1})==todayDate

}
export const isTomorrow = (dateString: kapitelDateString): boolean => {

  const todayDate = kapitelDateStringToLuxon(getToday())
  const queryDate = kapitelDateStringToLuxon(dateString)

  return todayDate.plus({days: 1})==queryDate
}

export const isWeekend = (dateString: kapitelDateString): boolean => {
  const idw = getISODayOfWeekFromKapitelDate(dateString)
  return idw==6||idw==7
}

/**
 * @param dateString
 * @param format https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 */
export const formatKapitelDate = (dateString: kapitelDateString, format: string): string => {
  return LuxonDateTime.fromISO(dateString, {zone: "utc", locale: allEmployeeLocale}).toFormat(format)
}

export const cmpKapitelDate = (string1: kapitelDateString, string2: kapitelDateString) => {
  // Returns -1 if string1 is less than string2; 1 if string1 is greater than string2, and 0 if they are equal.
  validateKapitelDate(string1)
  validateKapitelDate(string2)
  const date1 = new Date(string1)
  const date2 = new Date(string2)

  if(date1 < date2){
    return -1
  } else if (date2 < date1){
    return 1
  }
  return 0

}


export const weekdays = () => {
  const startOfWeek = LuxonDateTime.now().setZone(allEmployeeTimezone).startOf("week").startOf("day")
  const endOfWeek = LuxonDateTime.now().setZone(allEmployeeTimezone).endOf("week").endOf("day")
  const weekInterval = LuxonInterval.fromDateTimes(
    startOfWeek,
    endOfWeek
  )
  return weekInterval.splitBy({"days":1}).map((interval)=>{
    if(!interval.start){
      throw new Error("Invalid interval.")
    }
    const dateString = interval.start.toFormat('yyyy-MM-dd')
    return {
      day: getISODayOfWeekFromKapitelDate(dateString),
      labelShort: formatWeekdayShortFromKapitelDate(dateString),
      label: formatWeekdayFromKapitelDate(dateString)
    }
  })

}

export default {
  addDays,
  addWeeks,
  addMonths,
  allEmployeeLocale,
  allEmployeeTimezone,
  cmpKapitelDate,
  differenceInMonths,
  endOfMonth,
  formatKapitelDate,
  formatMonthFromKapitelDate,
  formatWeekdayFromKapitelDate,
  formatWeekdayShortFromKapitelDate,
  getDayOfMonthFromKapitelDate,
  getISODayOfWeekFromKapitelDate,
  getISOWeekNumberFromKapitelDate,
  formatISODayOfWeek,
  getMonthFromKapitelDate,
  getToday,
  getYearFromKapitelDate,
  isAfter,
  isBefore,
  min,
  max,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isMonday,
  isSameDay,
  isSameMonth,
  isTomorrow,
  isToday,
  isWeekend,
  isYesterday,
  startOfMonth,
  subDays,
  subMonths,
  validateKapitelDate,
  weekdays,
  startOfWeek,
  endOfWeek
}
