import {AvailabilityShift, AvailabilityShiftDefault, ShiftType, ShiftTimes} from "@/graphql/generated/graphql";
import { kapitelTimeString } from '@/graphql/kapitelTypes';

export const orderedShiftTypes: Array<string> = ["FD", "SD", "ND"];

export const getLabelForShift = (shift: string) : string => {
    switch(shift) {
        case 'FD': return 'Frühdienst'
        case 'SD': return 'Spätdienst'
        case 'ND': return 'Nachtdienst'
    }
    return ''
}

export const sortAvailabilityShifts = (a: AvailabilityShiftDefault | AvailabilityShift, b: AvailabilityShiftDefault | AvailabilityShift) => orderedShiftTypes.indexOf(a.type) - orderedShiftTypes.indexOf(b.type)

export const sortShifts = (a:ShiftType, b:ShiftType) => orderedShiftTypes.indexOf(a) - orderedShiftTypes.indexOf(b)


export const uniqueShifts = (shifts: Array<ShiftType>) => shifts.filter((j, i) => shifts.findIndex(j2 => j2 === j) === i)

export const getShiftForTime = (time?: kapitelTimeString, hullTimes?: Array<ShiftTimes>): ShiftType|undefined => {
    if (!time || !hullTimes) {
        return
    }
    
    for (const hullTime of hullTimes) {
        if (time >= hullTime.begin && time < hullTime.until) {
            return hullTime.type as ShiftType
        }
    }
}
