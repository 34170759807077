<template>
<div>
    <RichResponseHeadline
        :title="headline"
    />
    <CalendarListItem
        v-for="d in days"
        :key="d.date"
        :date="d.date"
        :day-data="d"
        v-on:click="()=>onClick(d.date)"
    />
    <CalendarDetail
        v-model="modalValue"
        ref="modal"
        :expected-content="expectedDetailContent"
    ></CalendarDetail>
</div>
</template>

<script setup lang="ts">
import RichResponseHeadline from "./components/RichResponseHeadline.vue";
import CalendarListItem from "./components/CalendarListItem.vue";
import date from "@/helper/datetime/date";
import {computed, ref, Ref, watch} from "vue";
import {Day} from "@/graphql/generated/graphql";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {fetchDaysForDateRange} from "@/helper/day";
import {DataLoader} from "@/helper/dataLoader";
import {generateCalendarDate} from '@/helper/calendar/calendarDate';
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import {useGlobalEmitter} from "@/helper/emitter";

const props = defineProps<{
    initData: kapitelDateString // week start
}>()

const days = ref<Day[]>([])
const dates = ref<kapitelDateString[]>([])


const modalValue: Ref<kapitelDateString|undefined> = ref(undefined)
const expectedDetailContent: Ref<"booking"|"availability"|undefined> = ref(undefined)
const modal = ref()

const onClick = (date: kapitelDateString) => {
    expectedDetailContent.value = days.value[dates.value.indexOf(date)].booking ? "booking" : "availability"
    modalValue.value = date
}

const headline = computed(() => {
    const from = dates.value[0]
    const to = dates.value[dates.value.length - 1]
    
    const calendarDateFrom = generateCalendarDate(from)
    const calendarDateTo = generateCalendarDate(to)

    const monthDiffers = calendarDateFrom.month !== calendarDateTo.month
    const yearDiffers = calendarDateFrom.year !== calendarDateTo.year

    const weekStr = `KW ${calendarDateFrom.week}`
    let yearAndMonthStr

    if (monthDiffers && yearDiffers) {
        yearAndMonthStr = `${calendarDateFrom.labelMonth} ${calendarDateFrom.year} / ${calendarDateTo.labelMonth} ${calendarDateTo.year}`
    }
    else if (monthDiffers) {
        yearAndMonthStr = `${calendarDateFrom.labelMonth} / ${calendarDateTo.labelMonth} ${calendarDateFrom.year}`
    }
    else {
        yearAndMonthStr = `${calendarDateFrom.labelMonth} ${calendarDateFrom.year}`
    }

    return `${weekStr} ${yearAndMonthStr}`
})

const loader = new DataLoader(
    async () => {
        const from = dates.value[0]
        const to = dates.value[dates.value.length - 1]

        days.value = await fetchDaysForDateRange(from, to)
    }
)

const init = () => {
    days.value = []
    dates.value = []
    modalValue.value = undefined
    expectedDetailContent.value = undefined

    let iterator = -1
    while(iterator++ < 6) {
        dates.value.push(date.addDays(props.initData, iterator))
    }

    loader.load()
}

// refresh content
useGlobalEmitter().on('day:mutated', (date) => {
    if (dates.value.includes(date)) {
        loader.load()
    }
})

// re-init on prop change
watch(() => props.initData, init)
init()

</script>
