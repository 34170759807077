<script setup lang="ts">
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonRow,
    IonIcon
} from "@ionic/vue";
import Modal from "@/components/Modal.vue";
import InputTime from "@/components/InputTime.vue";
import {computed, ref, Ref, toRaw, watch} from "vue";
import TextWithIcon from "@/components/TextWithIcon.vue";
import {TimesheetToDo} from "@/graphql/generated/graphql";

import {kapitelDateTimeString, kapitelTimeString,} from "@/graphql/kapitelTypes";
import InlineSVG from "@/components/InlineSVG.vue";
import datetime from "@/helper/datetime/datetime";
import dateHelper from "@/helper/datetime/date";
import {removeOutline, trashBinOutline} from "ionicons/icons";

const props = defineProps<{
    modelValue: boolean;
    timesheetToDo: TimesheetToDo | undefined;
}>();

/**
 * Modal v-model pass through
 */
const isOpen = ref(false);
const emit = defineEmits([
    "update:timesheet",
    "update:modelValue",
    "toggle:deselect:timesheet",
]);
watch(
    () => props.modelValue,
    (value) => {
        isOpen.value = value;
    },
);
watch(
    () => isOpen.value,
    (value) => {
        emit("update:modelValue", value);
    },
);

/**
 * regular data
 */
const valueBegin : Ref<kapitelDateTimeString | undefined> = ref(undefined);
const valueUntil : Ref<kapitelDateTimeString | undefined>  = ref(undefined);
const valueBreakBegin : Ref<kapitelDateTimeString | undefined>  = ref(undefined);
const valueBreakUntil : Ref<kapitelDateTimeString | undefined>  = ref(undefined);
const errorMessage : Ref<string> = ref("");

const date = computed(() => props.timesheetToDo ? datetime.convertDateTime2Date(props.timesheetToDo?.timesheet.begin) : undefined)

interface dateTimeInterval {
    begin: kapitelDateTimeString;
    until: kapitelDateTimeString;
}

const timeIntervalValuesToDatetime = (
    beginTime: kapitelTimeString,
    untilTime: kapitelTimeString,
): dateTimeInterval => {
    if (!date.value) {
        throw new Error('not ready')
    }
    const begin = datetime.convertDateAndTime2DateTime(date.value, beginTime);
    let until = datetime.convertDateAndTime2DateTime(date.value, untilTime);
    if (datetime.isAfter(begin, until)) {
        until = datetime.addDays(until, 1);
    }

    return {
        begin: begin,
        until: until,
    };
};

const timesToDatetimes = () => {

    if (!date.value) {
        throw new Error('not ready')
    }

    let beginDT: kapitelDateTimeString | null = null;
    let untilDT: kapitelDateTimeString | null = null;
    let breakUntilDT: kapitelDateTimeString | null = null;
    let breakBeginDT: kapitelDateTimeString | null = null;


    if(valueBegin.value && valueUntil.value) {
        beginDT = datetime.convertDateAndTime2DateTime(date.value, valueBegin.value);
        untilDT = datetime.convertDateAndTime2DateTime(date.value, valueUntil.value);

        // If the ending DateTime is before the beginning DateTime, adjust the ending DateTime to the next day.
        if (datetime.isAfter(beginDT, untilDT)) {
            untilDT = datetime.addDays(untilDT, 1);
        }

        if(valueBreakBegin.value && valueBreakUntil.value) {
            breakBeginDT = datetime.convertDateAndTime2DateTime(date.value, valueBreakBegin.value);
            breakUntilDT = datetime.convertDateAndTime2DateTime(date.value, valueBreakUntil.value);

            // If the break begins before the shift begins, adjust the break beginning to the next day.
            if (datetime.isAfter(beginDT, breakBeginDT)){
                breakBeginDT = datetime.addDays(breakBeginDT, 1);
                breakUntilDT = datetime.addDays(breakUntilDT, 1);
            }else if(datetime.isAfter(breakBeginDT, breakUntilDT)){
                // If the break ending is before the break beginning, adjust the break ending to the next day.
                breakUntilDT = datetime.addDays(breakUntilDT, 1);
            }
        }
    }

    return {
        beginDT: beginDT,
        untilDT: untilDT,
        breakUntilDT: breakUntilDT,
        breakBeginDT: breakBeginDT,
    }

}

const validSettings = () => {

    const { beginDT, untilDT, breakBeginDT, breakUntilDT } = timesToDatetimes()


    if (
            (typeof valueBreakBegin.value === "undefined") !==
            (typeof valueBreakUntil.value === "undefined")
    ) {
        errorMessage.value = "Entweder keine Pausenzeiten, oder Anfang und Ende setzen."
        return false;
    }

    let result = true;

    if (beginDT && untilDT) {

        result = result && datetime.isBefore(beginDT, untilDT);
        // should not happen
        !result ? (errorMessage.value = "Ungültige Dienstzeiten") : null;

        if (breakBeginDT && breakUntilDT) {

            result = result && datetime.isBefore(breakBeginDT, breakUntilDT);
            // debugger
            if(!result){
                // should not happen
                errorMessage.value = "Ungültige Pausenzeiten"
                return false
            }

            result = result && (
                datetime.isAfter(breakBeginDT, beginDT)
                && datetime.isAfter(untilDT, breakUntilDT)
            )

            if(!result){
                errorMessage.value = "Ungültige Pausenzeiten"
                return false
            }
        }
    }

    return result;
};

watch(
    () => props.timesheetToDo,
    (value) => {
        valueBegin.value = datetime.convertDateTime2Time(
            value?.timesheet?.begin,
        );
        valueUntil.value = datetime.convertDateTime2Time(
            value?.timesheet?.until,
        );
        valueBreakBegin.value = value?.timesheet?.breakBegin
            ? datetime.convertDateTime2Time(value?.timesheet?.breakBegin)
            : undefined;
        valueBreakUntil.value = value?.timesheet?.breakUntil
            ? datetime.convertDateTime2Time(value?.timesheet?.breakUntil)
            : undefined;
    },
    {
        immediate: true,
    },
);

const save = () => {
    if (!valueBegin.value || !valueUntil.value) {
        throw new Error('values unset')
    }

    const setValue = structuredClone(toRaw(props.timesheetToDo));

    const { beginDT, untilDT, breakBeginDT, breakUntilDT } = timesToDatetimes()

    setValue.timesheet.begin = beginDT
    setValue.timesheet.until = untilDT
    setValue.timesheet.breakBegin = breakBeginDT
    setValue.timesheet.breakUntil = breakUntilDT

    emit("update:timesheet", setValue);
    isOpen.value = false;
};

const deselectTimesheet = () => {
    emit("toggle:deselect:timesheet");
    isOpen.value = false;
};
</script>

<template>
    <Modal v-model="isOpen" :title="'Dienst am ' + dateHelper.formatKapitelDate(date, 'DD')">
        <ion-card>
            <ion-card-header>
                <ion-card-title>Dienstauswahl bearbeiten</ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <ion-button @click="deselectTimesheet" expand="block" color="light">
                    <IonIcon :icon="trashBinOutline" style="margin-right: 0.5em"></IonIcon>
                    Diesen Dienst später signieren
                </ion-button>
            </ion-card-content>
        </ion-card>
        <ion-card>
            <ion-card-header>
                <ion-card-title>Dienstzeit bearbeiten</ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <ion-row>
                    <ion-card-subtitle>Dienstzeit</ion-card-subtitle>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <InputTime label="Dienstbeginn" v-model="valueBegin" :nullable="false"/>
                    </ion-col>
                    <ion-col>
                        <InputTime label="Dienstende" v-model="valueUntil" :nullable="false"/>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-card-subtitle>Pausenzeit</ion-card-subtitle>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <InputTime label="Pausenbeginn" v-model="valueBreakBegin" :nullable="true" nullable-label="Leer"/>
                    </ion-col>
                    <ion-col>
                        <InputTime label="Pausenende" v-model="valueBreakUntil" :nullable="true" nullable-label="Leer"/>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <TextWithIcon
                        :small="true"
                        :warning="!validSettings()"
                        :success="validSettings()"
                    >
                        <template #icons>
                            <InlineSVG
                                v-if="!validSettings()"
                                src="/icons/info.svg"
                            />
                            <InlineSVG v-else src="/icons/check-circle.svg" />
                        </template>
                        <p>
                            {{ !validSettings() ? errorMessage : "" }}
                        </p>
                    </TextWithIcon>
                </ion-row>
            </ion-card-content>
        </ion-card>
        <ion-button
            @click="save"
            expand="block"
            :disabled="!validSettings()"
            color="primary"
        >
            OK
        </ion-button>
    </Modal>
</template>
