<template>
<div>
    <Skeleton v-if="loader.pending.value" :items="['title','block--height-12rem','block--height-4rem','block--height-4rem']"></Skeleton>
    <template v-else>

        <template v-if="renderSet.status === PlanningMode.Uncontracted">
            <span>Fehlender Arbeitsvertrag</span>
        </template>
        <template v-else-if="renderSet.status === PlanningMode.AvailabilityPlanning || renderSet.status === PlanningMode.BookingPlanning">

            <!-- bookings & bookingTarget: focusMode status -->
            <RichResponseListItem
                v-if="calculatedFocusMode==='status'"
                :icon="bookingRS.count.toString()"
                :title="bookingRS.text"
                :text="planningRS.count.toString() + ' ' + planningRS.text"
                @click="planningRS.click"
                :button="!!planningRS.click"
            >
                <template v-slot:end>
                    <ProgressCircle
                        class="listItemSuffixImage"
                        :stroke-width="15"
                        track-color="grey-tint-1"
                        stroke-color="black"
                        :value="bookingRS.completionRatio"
                        :glow="false"
                    />
                </template>
            </RichResponseListItem>
            <!-- bookings & bookingTarget: focusMode settings -->
            <RichResponseListItem
                v-else-if="calculatedFocusMode==='settings'"
                :icon="planningRS.count.toString()"
                icon-color="primary"
                :title="planningRS.text"
                :text="bookingRS.count.toString() + ' ' + bookingRS.text"
                @click="planningRS.click"
                :button="!!planningRS.click"
            >
                <template v-slot:end>
                    <IonIcon :icon="planningRS.icon" color="primary" class="listItemSuffixImage"></IonIcon>
                </template>
            </RichResponseListItem>

            <!-- availabilities -->
            <RichResponseListItem
                :icon="renderSet.availability.amount.toString()"
                icon-color="green"
                :title="renderSet.availability.text"
                :text="renderSet.availability.shiftText ? ('im ' + renderSet.availability.shiftText) : undefined"
            />

            <!-- jobsites - only if planning or startPlanningMode -->
            <RichResponseListItem
                v-if="renderSet.status === PlanningMode.BookingPlanning || startPlanningMode"
                :title="`${pluralizeUnitOnly(renderSet.planning?.jobsites?.length, 'Station', 'Stationen')} ausgewählt`"
                :icon="renderSet.planning?.jobsites?.length.toString()"
                icon-type="text"
                icon-color="grey"
                :text="`${jobsiteSummaryLabel(renderSet.planning.jobsites)} (${clientListLabel(renderSet.planning.jobsites)})`"
                @click="() => showModalFlags.jobsitePreferences = true"
                :button="true"
            />

            <!-- calendar - only if showCalendar -->
            <MonthCalendar
                v-if="props.showCalendar"
                :headline="null"
                :init-data="month"
                style="flex-grow: 1; margin-bottom: 0.5em"
            />

            <!--            <CalendarModal v-model="showModalFlags.listCalendar" :initial-date="calendarMonth.begin"></CalendarModal>-->

            <!-- start planning trigger button - only if unplanned AND not in "startPlanningMode" modal-->
            <RichResponseButton
                v-if="renderSet.status === PlanningMode.AvailabilityPlanning && !props.startPlanningMode"
                title="Dienstplanung starten"
                @click="() => showModalFlags.startPlanningWizard = true"
            />

            <!-- submodals -->
            <AutopilotJobsitePreference v-model="showModalFlags.jobsitePreferences" :month-id="props.month" />
            <AutopilotWorktimePreferences v-model="showModalFlags.worktimePreferences" :month="props.month" />
            <StartPlanningMonth
                v-if="renderSet.status === PlanningMode.AvailabilityPlanning"
                v-model="showModalFlags.startPlanningWizard"
                :month="props.month"
            ></StartPlanningMonth>

        </template>
    </template>
</div>
</template>

<script setup lang="ts">
import ProgressCircle from '@/components/ProgressCircle.vue'
import date from "@/helper/datetime/date"
import {computed, PropType, ref, Ref, watch} from "vue"
import {Jobsite, PlanningMode, PlanningStatus, ShiftType} from "@/graphql/generated/graphql";
import {clientListLabel, jobsiteSummaryLabel} from '@/helper/jobsite';
import {kapitelDateString} from '@/graphql/kapitelTypes';
import {fetchPlanningStatus, getAutopilotWorktimeModeIcon} from '@/helper/autopilotStatus';
import {generateCalendarMonth} from '@/helper/calendar/calendarMonth';
import {getLabelForPlanningStatus} from '@/helper/planningMode';
import {listFormat, pluralizeUnitOnly} from '@/helper/amountFormatter';
import {sortShifts} from '@/helper/shiftTypes';
import {IonIcon} from "@ionic/vue";
import {InstantDataLoader} from "@/helper/dataLoader";
import Skeleton from "@/components/Skeleton.vue";
import StartPlanningMonth from "@/views/components/Planning/StartPlanningMonth.vue";
import AutopilotJobsitePreference from "@/views/components/Planning/AutopilotJobsitePreference.vue";
import AutopilotWorktimePreferences from "@/views/components/Planning/AutopilotWorktimePreferences.vue";
import CalendarModal from "@/views/Calendar/CalendarModal.vue";
import MonthCalendar from "@/views/Chat/RichResponses/MonthCalendar.vue";
import {useGlobalEmitter} from "@/helper/emitter";
import RichResponseButton from "@/views/Chat/RichResponses/components/RichResponseButton.vue";
import RichResponseListItem from "@/views/Chat/RichResponses/components/RichResponseListItem.vue";

const props = defineProps({
    month: {
        type: String as PropType<kapitelDateString>,
        required: true
    },
    showCalendar: {
        type: Boolean,
        required: false,
        default: true
    },
    /** skip initial fetch */
    planningStatusPreload: {
        type: Object as PropType<PlanningStatus>,
        required: false,
        default: undefined
    },
    /** enables "start planning" mode */
    startPlanningMode: {
        type: Boolean,
        required: false,
        default: false
    },
    focusMode: {
        type: String as PropType<'status' | 'settings' | 'auto'>,
        required: false,
        default: 'auto'
    }
})
const calculatedFocusMode = computed(() => props.focusMode === 'auto' ? (renderSet.value.status === 'AVAILABILITY_PLANNING' ? 'settings' : 'status') : props.focusMode);

const calendarMonth = computed(() => generateCalendarMonth(props.month))

const emit = defineEmits(['details', 'startProcess'])

const showModalFlags = ref({
    startPlanningWizard: false,
    jobsitePreferences: false,
    worktimePreferences: false,
    listCalendar: false
})

const status : Ref<PlanningStatus|undefined> = ref(undefined);

const renderSet = computed(() => {
    const s = status.value
    const renderSet = {
        month: date.formatMonthFromKapitelDate(props.month),
        status: PlanningMode.Uncontracted as PlanningMode,
        monthIsStatusLabel: '',        
        availability: {
            shifts: [] as ShiftType[],
            shiftText: '',
            amount: 0,
            text: ''
        },
        booking: {
            booked: 0,
            jobsites: [] as Jobsite[],
            completed: false
        },
        planning: {            
            missing: 0,
            target: 0,
            targetReached: false,
            jobsites: [] as Jobsite[],
            favoriteJobsites: [] as Jobsite[],
            nonFavoriteJobsites: [] as Jobsite[],
        }
    }

    if (!s) {
        return renderSet
    }

    renderSet.status = s.planningMode
    renderSet.monthIsStatusLabel = getLabelForPlanningStatus(s, true)

    if (s.planningMode !== PlanningMode.Uncontracted) {
        renderSet.availability.shifts = s.availabilityShifts
        renderSet.availability.amount = s.availabilities
        renderSet.availability.text = pluralizeUnitOnly(renderSet.availability.amount, 'verfügbarer Tag', 'verfügbare Tage')
        renderSet.availability.shiftText = listFormat(renderSet.availability.shifts.toSorted(sortShifts))

        renderSet.booking.booked = s.bookings
        renderSet.booking.jobsites = s.bookedJobsites
        renderSet.booking.completed = s.bookingCompleted
    }
        
    if (s.autopilotStatus) {
        const jobsites = s.autopilotStatus.autopilotPreferences.autopilotJobsites.map(ajs => ajs.jobsite)
        renderSet.planning = {            
            missing: s.autopilotStatus.missingBookings,
            target: s.autopilotStatus.bookingTarget,
            targetReached: s.autopilotStatus.bookingHoursTargetReached,
            jobsites,
            favoriteJobsites: jobsites.filter(j => j.isFavorite),
            nonFavoriteJobsites: jobsites.filter(j => !j.isFavorite),
        }
    }

    return renderSet
})

const planningRS = computed(() => {
    const text = `${pluralizeUnitOnly(renderSet.value.planning.target, 'Dienst', 'Dienste')} Planungsziel`
    const count = renderSet.value.planning.target

    return {
        text,
        count,
        click: renderSet.value.status === 'BOOKING_PLANNING' || props.startPlanningMode
            ? () => { showModalFlags.value.worktimePreferences = true }
            : undefined,
        icon: getAutopilotWorktimeModeIcon(status.value?.autopilotStatus)
    }
})

const bookingRS = computed(() => {
    const text = `${pluralizeUnitOnly(renderSet.value.booking.booked, 'Dienst', 'Dienste')} bereits geplant`
    const count = renderSet.value.booking.booked

    let completionRatio = renderSet.value.planning.target > 0 ? (renderSet.value.booking.booked / renderSet.value.planning.target) : 0

    return {
        text,
        count,
        completionRatio,
    }
})

let preloadedPlanningStatus = props.planningStatusPreload
const loader = InstantDataLoader(
    async () => {
        console.log("load ", props.month)
        if (preloadedPlanningStatus) {
            status.value = preloadedPlanningStatus
            preloadedPlanningStatus = undefined
        } else  {
            status.value = await fetchPlanningStatus(calendarMonth.value)
        }
    }
)

useGlobalEmitter().on('day:mutated', (date) => {
    if (calendarMonth.value.containsDate(date)) {
        loader.load()
    }
})
useGlobalEmitter().on('autopilotPreferences:mutated', (month) => {
    if (month === props.month) {
        loader.load()
    }
})
useGlobalEmitter().on('availabilityPlanningMonth:mutated', (month) => {
    if (month === props.month) {
        loader.load()
    }
})

watch(() => props.month, () => {loader.load()})

</script>

<style lang="scss" scoped>
.listItemSuffixImage {
    width: 2.5em;
    height: 2.5em;
}

ion-button {
   margin-top: 0.5em;
}
</style>
