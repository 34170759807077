import {Ref, ref} from 'vue';

import {useEmployeeStore} from "@/store/employee";
import {useTimesheetStore} from "@/store/timesheet";

import useOfflineHelper from "@/helper/offline";
import {useAuthStore} from "@/store/auth";
import {useCandidateStore} from "@/store/candidate";
import {useAInesRequestStore} from "@/helper/chat/ainesRequests";

const intervalId : Ref<NodeJS.Timeout | undefined> = ref(undefined);

const disablePolling = import.meta.env.VITE_DEBUG_DISABLE_POLLING === 'true';

const start = async () => {
  const authStore = useAuthStore();

  const candidateStore = useCandidateStore();
  const employeeStore = useEmployeeStore()
  const timesheetStore = useTimesheetStore()

  const firstRun = async () => {
      // TODO: role handling
      if (authStore.isRoleCandidate()) {
          const candidatePromise = candidateStore.reloadCandidate()
          if (!candidateStore.storedCandidate){
              // on first fetch (after login) wait for employee
              await candidatePromise
          }

          // no first run
          // useAInesRequestStore().refetch()
      } else {
          const employeeLoadPromise = employeeStore.reloadEmployee()
          if (!employeeStore.storedEmployee) {
              // on first fetch (after login) wait for employee
              await employeeLoadPromise
          }

          timesheetStore.ensureInit()
      }
  }

  const cycle = async () => {
      // TODO: role handling
      if (authStore.isRoleCandidate()) {
          await Promise.all([
              candidateStore.reloadCandidate(),
              useAInesRequestStore().refetch()
          ])
      } else {
          await Promise.all([
              timesheetStore.sync(),
              employeeStore.reloadEmployee()
          ])
      }
  }

  // initial run
  await firstRun()

  if (disablePolling) {
    return
  }

  // cyclic run
  intervalId.value = setInterval(() => {
    if (useOfflineHelper().isOnline.value) {
      cycle()
    }
  }, 30000);
};


const stop = () => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
}


export function useCyclicRefresh() {
  return {
      start,
      stop
  };
}
