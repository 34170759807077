<script setup lang="ts">
import {computed, PropType} from "vue";
import {Demand} from "@/graphql/generated/graphql";
import {generateDemandSuggestionsRenderSet} from "@/helper/demand";
import {getShortJobsiteDescription} from "../helper/jobsite";
import {IonButton} from "@ionic/vue";

const props = defineProps({
    demands: {
        type: Array as PropType<Demand[]>,
        required: true,
    },
    editAvailabilityAction: Function,
    addAbsenceAction: Function,
    addAvailabilitiesAction: Function,
});

const renderSet = computed(() => generateDemandSuggestionsRenderSet(props.demands))

</script>

<template>
    <span v-if="demands.length == 1">
        Es gibt einen passenden Dienst im {{renderSet.shiftLabel}} auf {{getShortJobsiteDescription(renderSet.jobsites[0])}}.<br><br>

        Wenn du möchtest, füge direkt eine passende Verfügbarkeit hinzu oder trage ein Wunschfrei ein um die Information auszublenden.
    </span>
    <span v-else-if="demands.length > 1">
        Es gibt {{ demands.length }} passende Dienste:
        <ul>
            <li v-for="demand in demands" :key="demand.id">
                {{demand.shiftType}} auf {{getShortJobsiteDescription(demand.jobsite)}}
            </li>
        </ul>
        <br>
        Wenn du möchtest, füge direkt eine passende Verfügbarkeit hinzu oder trage ein Wunschfrei ein um die Information auszublenden.
    </span>

    <template v-if="editAvailabilityAction || addAbsenceAction || addAvailabilitiesAction">
        <br><br>
    </template>
    <ion-button
        v-if="editAvailabilityAction"
        @click="editAvailabilityAction"
        expand="block"
        color="light"
    >
        Verfügbarkeit bearbeiten
    </ion-button>
    <ion-button
        v-if="addAbsenceAction"
        @click="addAbsenceAction"
        expand="block"
        color="light"
    >
        Wunschfrei eintragen
    </ion-button>
    <ion-button
        v-if="addAvailabilitiesAction"
        @click="addAvailabilitiesAction"
        expand="block"
        color="primary"
    >
        {{ renderSet.shiftLabel }} verfügbar stellen

    </ion-button>
</template>
