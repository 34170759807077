import {useAuthStore} from "@/store/auth";
import {computed} from "vue";
import {useRoute} from "vue-router";
import {UseIonRouterResult} from "@ionic/vue/dist/types/hooks/router";
import {useAppState} from "@/helper/appState";

export type TabType = typeof availableTabs.value[0]

export const isTabActive = (tab : TabType) => {
    const route = useRoute()

    return route.path.startsWith(tab.href)
}

export const navigateToTab = (tab : TabType, ionRouter: UseIonRouterResult) => {
    ionRouter.navigate(tab.href, 'none', 'push')
}

export const availableTabs = computed(() => {
    const authStore = useAuthStore()
    return [
        {
            key: 'einsatz',
            href: '/planned',
            label: 'Einsätze',
            icon: '/icons/house.svg',
            enabled: useAppState().isEmployeeOrCandidate('employee'),
            visible: true

        },
        {
            key: 'planung',
            href: '/planning',
            label: 'Planung',
            icon: '/icons/strategy.svg',
            enabled: useAppState().isEmployeeOrCandidate('employee'),
            visible: true
        },
        {
            key: 'calendar',
            href: '/calendar',
            label: 'Kalender',
            icon: '/icons/calendar-blank.svg',
            enabled: useAppState().isEmployeeOrCandidate('employee'),
            visible: true
        },
        /* {
            key: 'settings',
            href: '/settings',
            label: 'Einstellungen',
            icon: settingsOutline,
            visible: true
        },*/
        {
            key: 'employees',
            href: '/employees',
            label: 'Mitarbeiter',
            icon: '/icons/detective.svg',
            visible: authStore.isRoleConsultant(),
            enabled: true
        },
        /* {
            key: 'mocks',
            href: '/mock',
            label: 'Mocks',
            icon: phonePortraitOutline,
            visible: env.isDevelopment(),
        }, */
    ].filter(tab => tab.visible)
})
