<template>
    <div v-if="hullTimes && jobsite" class="jobsite-info-card" :class="{'expanded': isExpanded}">
        <RichResponseListItem
            iconType="image"
            :icon="getImageForJobsite(jobsite)"
            :text="jobsite?.client?.abbreviation?.toString()"
            @click="isExpanded = props.expandable ? !isExpanded : false"
        >
            <template v-slot:title>
                {{ jobsite?.abbreviation?.toString() }}
                <ion-chip
                    class="small title-chip"
                >
                    ITS
                </ion-chip>
            </template>
            <template v-slot:end v-if="props.expandable">
                <ion-icon :src="isExpanded ? '/icons/caret-up.svg' : '/icons/caret-down.svg'" />
            </template>
        </RichResponseListItem>
        <Transitionator :types="['opacity', 'maxheight']">
            <div
                class="expanded-content-wrapper"
                v-if="isExpanded">
                <FlexWrapper>
                    <!-- Bild -->
                    <section
                        v-if="getImageForJobsite(jobsite)"
                        class="shrinkable section-image">
                        <img :src="getImageForJobsite(jobsite)">
                    </section>

                    <!-- Adresse -->
                    <section
                        v-if="jobsite?.address"
                        class="shrinkable">
                        <h3>Adresse</h3>
                        <p>
                            <template v-if="jobsite.address.district">
                                {{ jobsite.address.district }}<br><br>
                            </template>
                            {{ jobsite.address.street }}<br>
                            {{ jobsite.address.postalCode }} {{ jobsite.address.city }}
                        </p>
                    </section>

                    <!-- Fachbereiche -->
                    <section
                        v-if="jobsite?.fachbereiche?.length"
                        class="shrinkable">
                        <h3>Fachbereiche</h3>
                        <p>{{ jobsite.fachbereiche.map(fb => fb.name).join(', ') }}</p>
                    </section>
                    
                    <!-- Betten -->
                    <section
                        v-if="jobsite?.bedCount"
                        class="section-beds"
                        :class="{'shrinkable': !jobsite?.bedTypes}">
                        <h3>Betten</h3>
                        <div>
                            <div
                                class="bedcount">
                                <p>
                                    <span class="text-extralarge">{{ jobsite.bedCount }}</span>
                                    <ion-icon src="/icons/bed.svg" />
                                </p>
                            </div>
                            <div
                                class="bedtypes"
                                v-if="jobsite?.bedTypes">
                                <p>{{ jobsite.bedTypes }}</p>
                            </div>
                        </div>
                    </section>
                    
                    <!-- Schichten -->
                    <section
                        v-if="jobsite?.shiftTimes?.length"
                        class="shrinkable">
                        <h3>Schichten</h3>
                        <p>
                            <template v-for="shiftTime in jobsite.shiftTimes">
                                <ion-chip class="small">{{ getShiftForTime(shiftTime, hullTimes) }}</ion-chip>
                                <span>{{ shiftTime }}</span><br>
                            </template>
                        </p>
                    </section>
                    
                    <!-- Fachbereiche -->
                    <section
                        v-if="jobsite?.equipment?.length"
                        class="shrinkable">
                        <h3>Equipment</h3>
                        <p>{{ jobsite.equipment.join(', ') }}</p>
                    </section>

                    <!-- Betreuungsschlüssel -->
                    <section
                        v-if="jobsite?.careRatio"
                        class="shrinkable">
                        <h3>Betreuungsschlüssel</h3>
                        <p>{{ jobsite.careRatio }}</p>
                    </section>

                    <!-- Behandlungsspektrum -->
                    <section
                        v-if="jobsite?.medicalAbout">
                        <h3>Behandlungsspektrum</h3>
                        <p>{{ jobsite.medicalAbout }}</p>
                    </section>

                    <!-- Beschreibung -->
                    <!--<section
                        v-if="jobsite?.enrichedAbout || jobsite?.about">
                        <h3>Beschreibung</h3>
                        <p>{{ jobsite.enrichedAbout || jobsite.about }}</p>
                    </section>-->

                    <!-- Anforderungen -->
                    <!--<section
                        v-if="jobsite?.requirements"
                        >
                        <h3>Anforderungen</h3>
                        <p>{{ jobsite.requirements }}</p>
                    </section>-->

                    <!-- Wish Demand -->
                    <section
                        v-if="wishDemand && wishDemand.length">
                        <h3>Wunschdienste</h3>
                        <p>Du hast hier schon {{ wishDemand.length }} Dienste markiert.</p>
                    </section>

                    <!-- CTA -->
                    <div
                        class="section-actions"
                        >
                        <ion-button
                            expand="block"
                            fill="solid"
                            color="light"
                            @click="onClickButtonMoreInfo"
                        >
                        Mehr Infos
                        </ion-button>
                        <ion-button
                            expand="block"
                            fill="solid"
                            color="primary"
                            @click="onClickButtonSelect"
                            :disabled="isLoadingAddingFavoriteJobsite"
                        >
                            <ion-spinner name="crescent" color="light" v-if="isLoadingAddingFavoriteJobsite" />
                            <template v-else>
                                {{ isJobsiteFavorite ? 'Dienste anzeigen' : 'Merken' }}
                            </template>
                        </ion-button>
                    </div>
                </FlexWrapper>
            </div>
        </Transitionator>

        <JobsiteDetails
            v-model="jobsiteDetailModelValue"
        />

        <InitCandidatePromotionModal
            v-model="isCandidatePromotionModalActive" 
            v-on:submit="onCandidatePromotionSubmit"
            :useLastChatMessageAsIntroMessage="false"
            />
    </div>
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import Transitionator from "@/views/components/transitions/Transitionator.vue"
import JobsiteDetails from "@/views/JobsiteDetails.vue";
import FlexWrapper from '@/components/FlexWrapper.vue';
import InitCandidatePromotionModal from "@/views/components/InitCandidatePromotionModal.vue";
import {IonButton, IonChip, IonIcon, IonSpinner} from '@ionic/vue'
import {computed, onMounted, Ref, ref} from 'vue'
import {addFavoriteJobsite, fetchJobsiteDetail, getImageForJobsite} from '@/helper/jobsite'
import {fetchGlobalShiftHullTimes} from "@/helper/globalShiftTimes"
import {getShiftForTime} from "@/helper/shiftTypes"
import {DataLoader} from "@/helper/dataLoader.js";
import {AInesRichResponseType, Jobsite, Demand} from "@/graphql/generated/graphql";
import {useCandidateStore} from "@/store/candidate";
import {sendUserMessage} from "@/helper/chat/chatBL";
import { getWishDemand } from "@/helper/wishDemand"

const candidateStore = useCandidateStore()

const props = defineProps<{
    initData: string | number,
    expandable?: boolean
}>()

const jobsite:Ref<Jobsite|undefined> = ref()
const hullTimes = ref([])
const wishDemand = ref<Demand[]>([])
const isExpanded = ref(!props.expandable)
const jobsiteDetailModelValue = ref()
const isCandidatePromotionModalActive = ref()
const isLoadingAddingFavoriteJobsite = ref(false)

const candidate = computed(() => candidateStore.candidate)
const isJobsiteFavorite = computed(() => {
    return jobsite.value ? candidate.value?.favoriteJobsites.find(favoriteJobsite => favoriteJobsite.id === jobsite.value?.id) : false
})

const initialDataLoader = new DataLoader([
    async () => {
        jobsite.value = props.initData ? await fetchJobsiteDetail(parseInt(props.initData as string)) : undefined
    },
    async () => {
        hullTimes.value = await fetchGlobalShiftHullTimes()
    },
    async () => {
        wishDemand.value = await getWishDemand([parseInt(props.initData as string)])
    }
])

onMounted(initialDataLoader.load)

const onClickButtonSelect = () => {
    if (!jobsite.value) {
        return
    }
    
    if (isJobsiteFavorite.value) {

        sendUserMessage(
            "1. hole meine Wunschdienste von der Station\n" + jobsite.value.id +", 2. schaue ob es noch andere offene Dienste auf der Station gibt und zeige beides in einer Liste an.",
            {
                isScriptedContent:true
            }
        )
    }
    else {
        isLoadingAddingFavoriteJobsite.value = true

        addFavoriteJobsite(jobsite.value)
            .then(candidateStore.reloadCandidate)
            .then(() => {
                isLoadingAddingFavoriteJobsite.value = false
            })
    }
}

const onClickButtonMoreInfo = () => {
    jobsiteDetailModelValue.value = parseInt(props.initData as string)
}

const onCandidatePromotionSubmit = () => {
    console.log('ganz toll. wirklich.')
}

</script>

<style lang="scss" scoped>
ion-chip.title-chip {
    transform: translateY(-0.15em);
    flex: 0 0 auto;
}

.expanded-content-wrapper {
    width: 100%;
    --section-gap: 0.7em;

    > div {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        section {
            flex: 0 0 100%;
            background-color: var(--ion-color-light);
            border-radius: 1rem;
            padding: 1em;
            margin-top: var(--section-gap);

            &.shrinkable {
                flex: 1 0 calc(50% - var(--section-gap));
                
                &+.shrinkable:not(.flex-wrapped) {
                    margin-left: var(--section-gap);
                }
            }

            h3 {
                font-weight: var(--custom-font-weight-medium);
                font-size: var(--custom-font-weight-regular);
                margin-top: 0;
                margin-bottom: 3px;
            }

            *:not(h3) {
                color: var(--ion-color-grey);
            }
        }
    }
    
    
}

.jobsite-info-card {
    border-radius: 1rem;
    margin-bottom: 3rem;

    &.narrow {
        &.expanded {
            > ion-item::part(native) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .expanded-content-wrapper {
            --section-gap: 1px;
            background-color: var(--ion-color-grey-tint-1);
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;

            section {
                border-radius: 0;

                &:last-child {
                    border-bottom-left-radius: 1rem;
                    border-bottom-right-radius: 1rem;
                }
            }
        }
    }
}

.section-beds {
    > div {
        display: flex;
        align-items: center;

        .bedcount {
            p {
                display: flex;
                align-items: center;

                span {
                    margin: 0;
                    margin-right: 0.35em;
                    font-weight: var(--custom-font-weight-bold);
                }

                ion-icon {
                    font-size: var(--custom-font-size-extralarge);
                }
            }
        }

        .bedtypes {
            margin-left: 2em;
        }
    }
}

.section-image {
    padding: 0;
    overflow: hidden;
    position: relative;
    min-height: 8em;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.section-actions {
    width: 100%;
    display: flex;
    margin-top: var(--section-gap);

    ion-button {
        flex: 1 1 100%;
        margin: 0;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        ion-spinner {
            height: 1.2em;
            width: 1.2em;
        }
    }
}
</style>
