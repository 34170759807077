import {QuickChat} from "@/helper/chat/quickChat";
import {PreprocessedAssistantMessage, useChatStore} from "@/helper/chat/chatStore";
import {appendMessage, presentScriptedMessage, sendUserMessage} from "@/helper/chat/chatBL";
import {AInesInterestedInJobsiteTypes} from "@/graphql/generated/graphql";
import {
    calendarNumberOutline,
    compassOutline,
    diamondOutline,
    homeOutline,
    medkitOutline,
    peopleCircleOutline,
    playCircleOutline,
    searchOutline,
    sparklesOutline
} from "ionicons/icons";
import date, {formatKapitelDate} from "@/helper/datetime/date";

export interface ScriptedQuickChat extends QuickChat{
    click: () => any
}

export interface ScriptedMessage extends PreprocessedAssistantMessage {
}

const quickChatClickHandler = ({nextScriptedMessage, userPrompt}:{nextScriptedMessage?:ScriptedMessage, userPrompt:string}) => {
    const chatStore = useChatStore();

    if(chatStore.isFirstContact){
        chatStore.setIsFirstContact(false)
    }

    // TODO: centralize prepend 4
    const previousScriptedMessage = chatStore.scriptedMessage?.textContent
    if (nextScriptedMessage && previousScriptedMessage) {
        appendMessage(previousScriptedMessage, undefined, 'assistant').then(() => {
            appendMessage(userPrompt, undefined, 'user')
        })
        presentScriptedMessage(nextScriptedMessage)
    } else if (nextScriptedMessage) {
        appendMessage(userPrompt, undefined, 'user')
        presentScriptedMessage(nextScriptedMessage)
    } else {
        sendUserMessage(userPrompt, {isScriptedContent: true, prependedAssistantMessage: previousScriptedMessage})
    }
}

export const scriptedMessages : {
    firstCandidateContact: PreprocessedAssistantMessage,
    ibfgDescription: PreprocessedAssistantMessage
    stationsStoebernIntro: PreprocessedAssistantMessage
    afterPromotion: PreprocessedAssistantMessage
    afterSwitchToEmployee: PreprocessedAssistantMessage
}= {
    firstCandidateContact: {
            textContent: "Hi, ich hab gehört du interessierst dich für die IBFG oder bist direkt auf der Suche nach einem Zusatzdienst?\nIch würde dir gerne konkrete Dienste vorschlagen. Dafür brauche ich noch ein paar Informationen von dir. Bis dahin können wir allerdings schon einmal auf die Suche nach interessanten Stationen gehen.\nWo würdest du denn gerne arbeiten? Oder interessiert dich etwas anderes?",
            richContents: undefined,
            suggestedQuickChats: [
                {
                    label: "Stationen finden",
                    icon: searchOutline,
                    click: () => quickChatClickHandler({userPrompt: 'Ich würde gerne nach passenden Stationen suchen', nextScriptedMessage: scriptedMessages.stationsStoebernIntro})
                },
                {
                    label: "Was ist die IBFG?",
                    icon: '/icons/question.svg',
                    click: () => quickChatClickHandler({userPrompt:'Was ist die IBFG?', nextScriptedMessage: scriptedMessages.ibfgDescription})
                },
                {
                    label: "Dienste auswählen",
                    icon: calendarNumberOutline,
                    click: () => quickChatClickHandler({userPrompt: "Ich würde gerne direkt nach konkreten Diensten suchen."})
                },
                {
                    label: "Direkt zum Arbeitsvertrag.",
                    icon: playCircleOutline,
                    click: () => quickChatClickHandler({userPrompt: "Ich möchte gerne eine Anstellung bei der IBFG haben."})
                }
            ],
        },
    ibfgDescription: {
        textContent: `**Gemeinsam flexibel, selbstbestimmt und fair arbeiten.**\n
Wir verbinden Eigenverantwortung mit der Sicherheit eines Anstellungsverhältnisses. Du bestimmst deinen Dienstplan, wählst deine Kollegen mit aus und profitierst von einem transparenten, fairen System. Überschüsse gehen direkt an unsere Mitglieder zurück. Dank digitaler Tools und unseres virtuellen Assistenten INES wird dein Arbeitsalltag effizienter. Wir schaffen ein Arbeitsumfeld, das Mitbestimmung, Gemeinschaft und Flexibilität vereint.`,
        richContents: undefined,
        suggestedQuickChats: [
            {
                label: "Was verdiene ich hier?",
                icon: diamondOutline,
                click: () => quickChatClickHandler({userPrompt: "Was verdient man bei der IBFG?"})
            },
            {
                label: "Wo kann ich arbeiten?",
                icon: homeOutline,
                click: () => quickChatClickHandler({userPrompt: 'Bei welchen Kliniken kann ich eingesetzt werden?'})
            },
            {
                label: "Wie könnt ihr euch das leisten?",
                icon: peopleCircleOutline,
                click: () => quickChatClickHandler({userPrompt: 'Wie könnt ihr euch das leisten?'})
            },

        ],
    },
    stationsStoebernIntro: {
        textContent: "Um dir eine sinnvolle Auswahl an Stationen zu präsentieren brauche ich von dir einmal dein Interessensgebiet.",
        richContents: undefined,
        suggestedQuickChats: Object.values(AInesInterestedInJobsiteTypes)
            .map(v => ({
                label: v,
                icon: playCircleOutline,
                click: () => quickChatClickHandler({userPrompt: 'Ich interessiere mich für ' + v})
            }))
    },
    afterPromotion: {
        textContent: "Herzlichen Glückwunsch, die Dienstauswahl ist jetzt für dich freigeschaltet.\n\nDarf ich dir direkt ein paar Zusatzdienste vorschlagen? Suchst du nach offenen Diensten auf deinen gemerkten Stationen? Oder hast du schon eine konkrete Vorstellung, wann und wo du gerne arbeiten würdest?",
        richContents: undefined,
        suggestedQuickChats: [
            {
                label: "Auf der Merkliste suchen",
                icon: homeOutline,
                click: () => quickChatClickHandler({userPrompt: 'Gibt es offene Dienste auf den Stationen auf meiner Merkliste?'})
            },
            {
                label: "Schlag mir was vor!",
                icon: sparklesOutline,
                click: () => quickChatClickHandler({userPrompt:'Schlag mir offene Dienste vor!'})
            },
            {
                label: "Frühdienste am " + formatKapitelDate(date.addMonths(date.getToday(), 1) , 'd. MMMM'),
                icon: searchOutline,
                click: () => quickChatClickHandler({userPrompt: "Welche offenen Frühdienste gibt es am " + formatKapitelDate(date.addMonths(date.getToday(), 1) , 'd. MMMM')})
            }
        ],
    },
    afterSwitchToEmployee: {
        textContent: "Du bist jetzt als Mitarbeiter angemeldet, willkommen!",
        richContents: undefined,
    }
}

export const scriptedQuickChats : {
    interestedInSet: ScriptedQuickChat[],
}= {
    interestedInSet: [
        {
            label: "Schlag mir was vor!",
            icon: sparklesOutline,
            click: () => quickChatClickHandler({userPrompt: 'Kannst du mir ein paar Stationen vorschlagen?'})
        },
        {
            label: "In welchen Bezirken kann ich suchen?",
            icon: compassOutline,
            click: () => quickChatClickHandler({userPrompt: 'In welchen Bezirken kann ich suchen?'})
        },
        {
            label: "Welche Fachbereiche stehen zur Auswahl?",
            icon: medkitOutline,
            click: () => quickChatClickHandler({userPrompt: 'Welche Fachbereiche stehen zur Auswahl?'})
        },
    ]
}
