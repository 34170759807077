<template>
<div>
    <RichResponseHeadline
        title="Neuer Dienst"
    />
    <RichResponseListItem
        :title="renderSet.daysTitle"
        :text="renderSet.daysText"
        :icon="renderSet.daysIcon"
        :icon-blurred="!renderSet.daysIsSet"
        icon-type="text"
    />
    <RichResponseListItem
        :title="renderSet.shiftTitle"
        :text="renderSet.shiftText"
        :icon="renderSet.shiftIcon"
        :icon-blurred="!renderSet.shiftIsSet"
        icon-type="text"
    />
    <RichResponseListItem
        :title="renderSet.jobsiteTitle"
        :text="renderSet.jobsiteText"
        :icon="renderSet.jobsiteIcon"
        :icon-blurred="!renderSet.jobsiteIsSet"
        :icon-type="renderSet.jobsiteIsSet ? 'image' : 'text'"
    />
    <RichResponseButton
        title="Dienste eintragen"
        @click="onButtonClick"
    />
</div>

    <RegisterBookingModal v-model="showRegisterBooking" :init-data="registerBookingParams"></RegisterBookingModal>
</template>

<script setup lang="ts">
import date from "@/helper/datetime/date";
import RichResponseHeadline from "./components/RichResponseHeadline.vue";
import RichResponseListItem from "./components/RichResponseListItem.vue";
import {IonButton} from '@ionic/vue';
import {computed, Ref, ref, watch} from "vue";
import {Jobsite, ShiftType} from "@/graphql/generated/graphql";
import {getLabelForShift, orderedShiftTypes} from '@/helper/shiftTypes';
import {getImageForJobsite} from '@/helper/jobsite';
import {kapitelDateString} from '@/graphql/kapitelTypes';
import {resolveJobsite, shiftDate} from "@/helper/registerBooking";
import RegisterBookingModal from "@/views/components/RegisterBookingModal.vue";
import RichResponseButton from "@/views/Chat/RichResponses/components/RichResponseButton.vue";

export type registerBookingParameter = {jobsite: Jobsite | undefined, shiftDates: shiftDate[] | undefined}


const registerBookingParams : Ref<registerBookingParameter> = ref({jobsite: undefined, shiftDates: undefined})
const showRegisterBooking: Ref<boolean> = ref(false);

const onButtonClick = () => {
    let shiftDates = undefined
    if (props.initData.dates && props.initData.shift) {
        shiftDates = props.initData.dates.map(d => ({
            type: props.initData.shift,
            date: d
        }) as shiftDate)
    }
    registerBookingParams.value = {
        jobsite: resolvedJobsite.value,
        shiftDates: shiftDates,
    }

    showRegisterBooking.value = true
}

const props = defineProps<{
    initData: {
        jobsiteSearchString: string,
        dates: Array<kapitelDateString>,
        shift: ShiftType
    }
}>()

const resolvedJobsite = ref();
const updateResolvedJobsite = async () => {
    if(props.initData.jobsiteSearchString){
        resolvedJobsite.value = await resolveJobsite(props.initData.jobsiteSearchString)
    }
}
watch(() => props.initData.jobsiteSearchString, updateResolvedJobsite);
updateResolvedJobsite()

const parseShift  = (shift:any) => {
    if(orderedShiftTypes.indexOf(shift)){
        return shift
    }
    return undefined
}

const parseDates = (dates:any):kapitelDateString[] => {
    const result:kapitelDateString[] = []
    if(!dates){
        return []
    }
    if(Array.isArray(dates)){
        dates.forEach((d:kapitelDateString)=>{
            try{
                date.validateKapitelDate(d)
                result.push(d)
            }catch{}
        })
    }
    return result
}

const renderSet = computed(() => {

    const parsedShift = parseShift(props.initData.shift)
    const parsedDates = parseDates(props.initData.dates)
    return {
        shiftIcon: parsedShift || '?',
        shiftTitle: parsedShift ? getLabelForShift(parsedShift) : 'Schicht',
        shiftText: 'die Uhrzeit der Schicht' || 'noch nicht gesetzt',
        shiftIsSet: parsedShift !== undefined,

        jobsiteIcon: resolvedJobsite.value ? getImageForJobsite(resolvedJobsite.value) : '?',
        jobsiteTitle: resolvedJobsite.value?.name || 'Einsatzort',
        jobsiteText: !resolvedJobsite.value ? 'noch nicht gesetzt' : resolvedJobsite.value?.client?.name,
        jobsiteIsSet: !!resolvedJobsite.value,

        daysIcon: parsedDates ? parsedDates?.length.toString() : '?',
        daysTitle: parsedDates?.length === 1 ? 'Tag' : 'Tage',
        daysText: parsedDates ? parsedDates.map((d) => date.formatKapitelDate(d, 'd. L. yy')).join(', ') : 'noch nicht gesetzt',
        daysIsSet: parsedDates.length > 0
    }
})
</script>

<style lang="scss" scoped>
ion-button {
    margin-top: 0.5em;
}
</style>
