import {defineStore} from 'pinia'

import {Candidate} from "@/graphql/generated/graphql";
import {fetchCandidate} from "@/helper/candidate";
import {useGlobalEmitter} from "@/helper/emitter";
import {switchToEmployee} from "@/helper/auth";

export const useCandidateStore = () => {

    const storeDefinition = defineStore("candidate", {
        persist: true,
        state: (): { candidate: Candidate | undefined } => ({
            candidate: undefined
        }),
        getters: {
            storedCandidate: state => state.candidate,
            isPromoted: state => state.candidate?.promoted,
        },
        actions: {
            storeCandidate(candidate: Candidate) {
                this.candidate = candidate
            },
            async ensureCandidate(): Promise<Candidate> {
                if (this.candidate) {
                    return this.candidate
                }
                return await this.reloadCandidate()
            },
            async reloadCandidate(purge = false): Promise<Candidate> {
                if (purge) {
                    this.candidate = undefined
                }
                const candidate = await fetchCandidate()
                this.storeCandidate(candidate)


                if(candidate.readyToBecomeEmployee){
                    switchToEmployee()
                }

                return candidate
            },
            async getCandidate(): Promise<Candidate> {
                return this.candidate || await this.reloadCandidate()
            },
        },
    })

    const store = storeDefinition()

    const refreshCandidateIfNeeded = async () => { if (store.storedCandidate) {store.reloadCandidate()} }

    useGlobalEmitter().on('CandidateUpdated', refreshCandidateIfNeeded)

    return store
}
