<script setup lang="ts">
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonSpinner} from '@ionic/vue';
import {computed, PropType, ref, Ref, watch} from 'vue';
import {
    fetchDaysForDateRange,
    yieldEffectiveAbsences,
    yieldEffectiveAvailabilities,
    yieldEffectiveBookings
} from "@/helper/day";
import Modal from '@/components/Modal.vue'
import date, {getISODayOfWeekFromKapitelDate} from "@/helper/datetime/date";
import {DataLoader} from "@/helper/dataLoader.js";
import {
    AutopilotPreferenceObject,
    fetchAutopilotPreferenceObject,
    fetchPlanningTemplate
} from "@/helper/autopilotPreference.js";
import {generateCalendarMonth} from "@/helper/calendar/calendarMonth";
import Skeleton from "@/components/Skeleton.vue";

import {kapitelDateString} from '@/graphql/kapitelTypes';
import {
    AInesAssistantType,
    AInesRichResponseType,
    Availability,
    AvailabilityShift,
    AvailabilityShiftDefault,
    Day,
    PlanningStatus,
    PlanningTemplate
} from "@/graphql/generated/graphql";
import {generateAvailability, generatePropertiesFromDefault, hasShifts} from "@/helper/availability";
import {ExpectedContent} from "@/views/CalendarDetail/CalendarDetail.vue";
import {WeekdayNumbers} from "luxon";
import {fetchAvailabilityShiftDefaults} from "@/helper/availabilityShiftDefault";
import {setAvailabilityAbsence} from "@/helper/availabilityAbsence";
import {fetchAvailabilityPlanningDate, setAvailabilityPlanningDate} from "@/helper/availabilityPlanningDates";
import {pluralize} from "@/helper/amountFormatter";
import {fetchPlanningStatus, getAutopilotWorktimeModeLabel} from "@/helper/autopilotStatus";
import {clientListLabel, jobsiteSummaryLabel} from "@/helper/jobsite";
import {useGlobalEmitter} from "@/helper/emitter";
import StartPlanningMonthConfirm from "@/views/components/Planning/StartPlanningMonthConfirm.vue";
import {sendUserMessage} from "@/helper/chat/chatBL";
import PlanningStatusAndPreferences from "@/views/components/Planning/PlanningStatusAndPreferences.vue";


/**
 * args
 */
const showModal = defineModel({type: Boolean})
const modalRef = ref()
const props = defineProps({
    month: {
        type: String as PropType<kapitelDateString>,
        required: true
    }
})
const calendarMonth = computed(() => generateCalendarMonth(props.month))

/**
 * fetch preferences for month, checking
 * * if there are already preferences made
 * * if the employee is planable this month (by existence)
 */
const autopilotPreferences : Ref<undefined|AutopilotPreferenceObject> = ref(undefined)
const loadAutopilotPreferences = async () => autopilotPreferences.value = await fetchAutopilotPreferenceObject(calendarMonth.value)
const monthPlanable = computed(() => !!autopilotPreferences.value && nextPlannableMonth.value === calendarMonth.value.getId())

/**
 * planning status
 */
const planningStatus : Ref<PlanningStatus|undefined> = ref(undefined)
const loadPlanningStatus = async () => planningStatus.value = await fetchPlanningStatus(calendarMonth.value)


/**
 * fetch planning template for availability suggestions
 */
const planningTemplate : Ref<undefined|PlanningTemplate> = ref(undefined)
const loadPlanningTemplate = async () => planningTemplate.value = await fetchPlanningTemplate(calendarMonth.value)
const suggestions = computed(() => {
    return (planningTemplate.value?.availabilityShiftSuggestion || [])
        .filter(o => o.shifts.length > 0)
})
const getSuggestionForDay = (date: kapitelDateString) => {
    return suggestions.value.find(o => o.dow === getISODayOfWeekFromKapitelDate(date))
}

/**
 * fetch availability dates to ensure only n+1 planning start
 */
const planningDate : Ref<kapitelDateString|undefined> = ref(undefined)
const loadPlanningDate = async () => planningDate.value = await fetchAvailabilityPlanningDate()
const nextPlannableMonth = computed(() => planningDate.value ? date.addMonths(planningDate.value, 1) : undefined)


/**
 * fetch day data
 */
type DayMap = {
    [date: kapitelDateString]: Day;
};
const days = ref<DayMap>({})
const loadDays = async () => {
    const data = await fetchDaysForDateRange(calendarMonth.value.begin, calendarMonth.value.until)
    data.forEach(day => {
        days.value[day.date] = day
    })
}

/**
 * fetch shift defaults as well so that we can create new availabilities
 */
const availabilityShiftDefaults = ref(undefined) as Ref<undefined|AvailabilityShiftDefault[]>
const loadShiftDefaults = async () => availabilityShiftDefaults.value = await fetchAvailabilityShiftDefaults()


/**
 * template data / rendersets
 */

const dismissAvailabilitySuggestion = ref(false)

const saving = ref(false);

const rs = computed(() => {
    const rs = {
        monthLabel: calendarMonth.value.format('MMMM'),
        monthYearLabel: calendarMonth.value.format('MMMM yyyy'),

        bookingTarget: 0,
        bookingTargetTitle: 'Planungsziel',
        bookingTargetSubtitle: '',

        jobsiteTitle: 'Stationsauswahl',
        cntJobsite: 0,
        jobsiteSubtitle: '',
        cntAvailabilities: 0,
        cntBookings: 0,
        cntAbsences: 0,
        cntSuggestions: 0,

        // availabilityIcon: '',
        // availabilityTitle: 'Verfügbarkeiten',
        // availabilitySubtitle: '',
    }

    const preferences = autopilotPreferences.value
    const jobsites = preferences?.autopilotJobsites.map(ajs => ajs.jobsite) || []

    if (preferences) {
        rs.bookingTarget = preferences.bookingTarget || planningTemplate.value?.bookingTargetSuggestion || 0
        rs.bookingTargetTitle = pluralize(rs.bookingTarget, 'Dienst', 'Dienste') + ' Planungsziel'
        rs.bookingTargetSubtitle = getAutopilotWorktimeModeLabel(preferences.worktimeMode) as string

        rs.cntJobsite = jobsites.length
        rs.jobsiteTitle = `${pluralize(jobsites.length, 'Station', 'Stationen')} ausgewählt`
        rs.jobsiteSubtitle = `${jobsiteSummaryLabel(jobsites)} (${clientListLabel(jobsites)})`
    }

    if (days.value) {
        rs.cntAvailabilities = yieldEffectiveAvailabilities(Object.values(days.value)).filter(a => hasShifts(a)).length
        rs.cntBookings = yieldEffectiveBookings(Object.values(days.value)).length
        rs.cntAbsences = yieldEffectiveAbsences(Object.values(days.value)).length
        rs.cntSuggestions = suggestions.value.length
    }

    return rs
})

const rsAvailabilitySuggestions = computed(() => {
    const formatter = new Intl.ListFormat('de', { style: 'long', type: 'conjunction' });

    const dayShiftLabels = suggestions.value.map(o => `${date.formatISODayOfWeek(o.dow as WeekdayNumbers, 'cccc')}s (${o.shifts./*map(getLabelForShift).*/join(', ')})`)

    return {
        show: suggestions.value.length > 0 && !dismissAvailabilitySuggestion.value,
        label: `Immer ${formatter.format(dayShiftLabels)}.`
    }
})

const showModalFlags = {
    jobsitePreferences: ref(false),
    worktimePreferences: ref(false),
    dayDetailsDate: ref(undefined) as Ref<kapitelDateString|undefined>,
    dayDetailsExpectedContent: ref(undefined) as Ref<ExpectedContent>,
    confirm: ref(false)
}

const onApplyAvailabilitySuggestion = async () => {
    dismissAvailabilitySuggestion.value = true
    saving.value = true

    const availabilities = calendarMonth.value.getKapitelDates()
        .map(date => {
            const suggestion = getSuggestionForDay(date)
            if (!suggestion) {
                return undefined
            }

            const availability = generateAvailability(date)

            const defaults = availabilityShiftDefaults.value;
            if (!defaults) {
                return undefined
            }

            availability.shifts = suggestion.shifts
                .map(shift => {
                    const shiftDefault = defaults.find((shiftDef:AvailabilityShiftDefault) => shiftDef.type === shift)
                    return shiftDefault ? generatePropertiesFromDefault(date, shiftDefault) : undefined
                })
                .filter((shift):shift is AvailabilityShift => !!shift)

            return availability
        })
        .filter((availability): availability is Availability => !!availability)

    return Promise
        .all(availabilities.map(availability => setAvailabilityAbsence(availability, null)))
        .then(() => saving.value = false)
}

const onSingleDateClick = (data) => {
    const date = data.date as kapitelDateString
    showModalFlags.dayDetailsDate.value = date as kapitelDateString
    showModalFlags.dayDetailsExpectedContent.value = days.value[date].booking ? 'booking' : 'availability'
}

const onStartPlanning = async () => {
    saving.value = true
    const result = await setAvailabilityPlanningDate(calendarMonth.value.getId())
    if (result) {
        // a) dont trigger new run
        // await appendMessage('Ich habe die Dienstplanung für ' + rs.value.monthLabel + ' gestartet')
        // showModal.value = false

        // b) trigger new run
        modalRef.value.dismiss()
            .then(() => {
                sendUserMessage(
                    'Vielen Dank, ich konnte über das Interface die Dienstplanung für ' + rs.value.monthLabel + ' starten.',
                    {
                        messageExpertAssistant: AInesAssistantType.Planning,
                        richContentsPreview: [
                            {
                                type: AInesRichResponseType.AutopilotPlanningStatusSummary,
                                initData: calendarMonth.value.getId()
                            }
                        ],
                        isScriptedContent: true
                    }

                )
            })
    }
    saving.value = false
}
watch (showModal, (v) => console.log(v))


/**
 * init
 */
const loader = new DataLoader([loadAutopilotPreferences, loadPlanningTemplate, loadDays, loadShiftDefaults, loadPlanningDate, loadPlanningStatus])

const init = () => {
    dismissAvailabilitySuggestion.value = false
    loader.reload()
}

const onDidPresent = () => {
    init()
}


useGlobalEmitter().on('day:mutated', (date) => {
    if (calendarMonth.value.containsDate(date)) {
        loader.load()
    }
})
useGlobalEmitter().on('autopilotPreferences:mutated', (month) => {
    if (month === calendarMonth.value.getId()) {
        loader.load()
    }
})

</script>

<template>
<Modal v-model="showModal" :title="`${rs.monthYearLabel}`" @view:didPresent="onDidPresent" :fullscreen="true" :toolbar="true" ref="modalRef">
    <Skeleton :items="['block--height-4.5em', 'block--height-4.5em', 'block', 'block--height-12em','block','block']" v-if="loader.pending.value"></Skeleton>
    <div v-else-if="!monthPlanable">
        <IonCard>
            <IonCardHeader>
                {{rs.monthLabel}} noch nicht planbar
            </IonCardHeader>
            <IonCardContent>
                Die Dienstplanung für {{rs.monthLabel}} kann noch nicht gestartet werden. Der nächste planbare Monat ist {{nextPlannableMonth ? date.formatMonthFromKapitelDate(nextPlannableMonth) : '?'}}.
            </IonCardContent>
        </IonCard>
    </div>
    <div v-else>


        <div class="availability-suggestions" v-if="rsAvailabilitySuggestions.show">
            <div class="availability-suggestions--content">
                <div class="text-bold">Vorschlag für deine Verfügbarkeiten:</div>
                <div>{{rsAvailabilitySuggestions.label}}</div>
                <div>&nbsp;</div>
                <div>Du kannst die Vorschläge nach dem Übernehmen jederzeit anpassen.</div>
            </div>
            <div class="availability-suggestions--actions">
                <IonButton color="transparent" @click="onApplyAvailabilitySuggestion">Jetzt übernehmen</IonButton>
                <IonButton color="transparent" @click="() => dismissAvailabilitySuggestion = true">Nein, danke</IonButton>
            </div>
        </div>

        <PlanningStatusAndPreferences :month="props.month" :start-planning-mode="true" :focus-mode="'settings'"></PlanningStatusAndPreferences>

    </div>
    <template v-slot:actions>
        <ion-button
            :disabled="!monthPlanable || saving"
            @click="showModalFlags.confirm.value = true"
            expand="block"
            color="primary"
        >
            <IonSpinner name="circles" v-if="saving"></IonSpinner>
            Planung für {{ rs.monthLabel }} starten
        </ion-button>
        <ion-button
            expand="block"
            color="secondary"
            fill="clear"
            @click="showModal=false"
        >
            Abbrechen
        </ion-button>
    </template>
    <StartPlanningMonthConfirm
        v-model="showModalFlags.confirm.value"
        :month="calendarMonth.getId()"
        :availability-amount="rs.cntAvailabilities"
        :jobsite-amount="rs.cntJobsite"
        :booking-target="rs.bookingTarget"
       @confirm="onStartPlanning"
    />
</Modal>
</template>

<style lang="scss" scoped>

.rich-month-calendar {
    background-color: var(--ion-color-light);
    border-radius: 1rem;
    padding: 0.5em 1em 1em 1em;
    width: 100%;
    margin-top: 0.5em;
}

.calendar-date-content {
    $SIZE: 0.5em;
    width: $SIZE;
    height: $SIZE;
    border-radius: 50%;
    transform: translateY(1.1em);

    &.booked {
        background-color: var(--ion-color-black-tint);
    }

    &.available {
        background-color: var(--ion-color-green);
    }

    &.suggestion {
        background-color: var(--ion-color-yellow);
    }

    &.absence {
        background-color: var(--ion-color-red);
    }
}
.availability-suggestions {
    margin-left: calc(var(--custom-spacing-app-content-padding-horizontal)  / -2);
    margin-right: calc(var(--custom-spacing-app-content-padding-horizontal)  / -2);
    border-radius: 1rem;
    background-color: var(--ion-color-light);
    //margin-top: 1rem;
    margin-bottom: 1rem;

    > .availability-suggestions--content {
        padding: 1rem;
        border-bottom: 1px solid var(--ion-color-grey-tint-2);

        > * {
            line-height: 1.4em;
        }
    }

    > .availability-suggestions--actions {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        > * {
            flex: 1 1 auto;
            margin: 0;

            &:not(:last-child) {
                border-right: 1px solid var(--ion-color-grey-tint-2);
            }

            &:first-child {
                color: var(--ion-color-success);
            }
        }
    }
}
</style>
