<script setup>
import { IonInput, IonPopover, IonDatetime, IonButtons, IonButton } from '@ionic/vue'
import {ref, watch, onMounted, nextTick, computed} from 'vue'
import datetime from "@/helper/datetime/datetime"
import time from '@/helper/datetime/time';

const props = defineProps({
    modelValue: String,
    label: String,
    placeholder: String,
    nullable: {
        type: Boolean,
        default: true,
        required: false
    },
    nullableLabel: {
        type: String,
        default: 'Egal',
        required: false
    }
})

const input = ref(null)
const picker = ref(null)
const isActive = ref(false)
const inputValue = ref(undefined)
const pickerValue = ref(undefined)

const emit = defineEmits(['update:modelValue'])

watch(() => props.modelValue, value => {
    inputValue.value = value
}, {
    immediate: true
})

watch(() => inputValue.value, value => {
    emit('update:modelValue', value)
}, {
    immediate: true
})

const onClickOk = () => {
    picker.value.$el.confirm()
    inputValue.value = pickerValue.value
    isActive.value = false
}

const onClickCancel = () => {
    picker.value.$el.cancel()
    isActive.value = false
}

const onClickReset = () => {
    picker.value.$el.reset()
    inputValue.value = undefined
    isActive.value = false
}

const onClickInput = e => {
    e.target.blur()

    if (props.modelValue) {
        const inputValues = props.modelValue.split(':')
        const hours = parseInt(inputValues[0])
        const minutes = parseInt(inputValues[1])

        if (!isNaN(hours) && !isNaN(minutes)) {
            pickerValue.value = time.parseMinutes(hours*60+minutes)
        }
    }

    /**
     * Datetimepicker inside popover seems buggy with
     * v-model value. Nice hack, isnt it? -.-
     */
    setTimeout(() => {
        isActive.value = !isActive.value
    }, 100)
}

onMounted(() => {
    pickerValue.value = datetime.convertDateTime2Time(datetime.getNow())
})

const buttonLabel = computed(() => ({
    ok: 'OK',
    cancel: props.nullable ? null : 'Abbrechen',
    reset: props.nullableLabel
}))
</script>

<template>
    <ion-input
        ref="input"
        :label="props.label"
        :placeholder="props.placeholder"
        label-placement="stacked"
        @click="onClickInput"
        v-model="inputValue"
    />
    <ion-popover
        :is-open="isActive"
        @didDismiss="isActive = false"
    >
        <ion-datetime
            ref="picker"
            v-model="pickerValue"
            presentation="time"
            :show-default-title="true"
        >
            <span slot="title">
                {{ props.label }}
            </span>
            <ion-buttons slot="buttons">
                <ion-button v-if="nullable" color="red" @click="onClickReset()">{{ buttonLabel.reset }}</ion-button>
                <ion-button v-if="!nullable" color="dark" @click="onClickCancel()">{{ buttonLabel.cancel }}</ion-button>
                <ion-button color="dark" @click="onClickOk()">{{ buttonLabel.ok }}</ion-button>
            </ion-buttons>
        </ion-datetime>q
    </ion-popover>
</template>
