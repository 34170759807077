import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {Demand} from "@/graphql/generated/graphql";
import {clientFragment, houseFragment, jobsiteFragment} from "./jobsite";
import {listFormat, pluralizeUnitOnly} from "@/helper/amountFormatter";
import {sortShifts, uniqueShifts} from "@/helper/shiftTypes";


export async function fetchMatchingDemandIds(): Promise<any> {
    const result = await query(gql`
        query QueryDemandIds {
            demand(useStuffing: false) {
                id
                begin
                until
                jobsite {
                    id
                }
              }
        }`
    )

    return result?.data?.demand
}

export const getDemand = async (ids: Array<number>): Promise<any> => {
    const result = await query(gql`
        ${clientFragment}
        ${houseFragment}
        ${jobsiteFragment}
            query QueryDemand (
                $ids: [Int!]!
            ){
              demandByIds (
                ids: $ids
              ){
                id,
                begin,
                until,
                shiftType,
                jobsite {
                    ...JobsiteFragment
                    client {
                        ...ClientFragment
                    }
                    house {
                        ...HouseFragment
                    }
                },
                isWishDemand
              }
        }`,
        {
            ids: ids
        }
    )

    return result?.data?.demandByIds
}

export async function availabilityFromDemand(demandId: number): Promise<any> {

    const MUTATION = gql`
            mutation AddAvailabilityFromDemand (
                $demandIdDings: Int!
            ) {
                availabilityFromDemand(
                    demandId: $demandIdDings,
                )
            }`

    const result = await mutation(
        MUTATION, {
            demandIdDings: demandId,
        }
    )

    const response = result?.data?.availabilityFromDemand

    if (!response) {
        return 'Fehler beim Speichern'
    }

    return response
}

export const generateDemandSuggestionsRenderSet = (demand: Array<Demand>) => {
    const jobsites = demand.map(ds => ds.jobsite)
    const jobsiteLabel = listFormat(jobsites.map(js => js?.abbreviation || ''))
    const shifts = uniqueShifts(demand.map(ds => ds.shiftType)).toSorted(sortShifts)
    const shiftLabel = listFormat(shifts)
    const count = demand.length
    const label = pluralizeUnitOnly(count, 'Offener ' + shiftLabel, 'Offene ' + shiftLabel) + ' auf ' + jobsiteLabel

    return {
        count,
        jobsites,
        jobsiteLabel,
        shifts,
        shiftLabel,
        label
    }
}
