<template>
    <Modal :title='dateId?date.formatKapitelDate(dateId, "d. MMMM yyyy"):"Tagesdetails"' v-model="isOpen">
            <template v-if="loader.pending.value">
                <!-- Booking Skeleton -->
                <Skeleton
                    v-if="expectedContent === 'booking'"
                    :items="['block--height-10rem', 'margin', 'title', 'margin', 'block--height-10rem']"
                ></Skeleton>

                <!-- Availability Skeleton -->
                <Skeleton
                    v-else
                    :items="['title', 'block--height-4em', 'block--height-4em', 'block--height-4em']"
                ></Skeleton>
            </template>
            <template v-else>
                <!-- Booking Details -->
                <BookingDetails
                    v-if="booking"
                    :booking="booking"
                />

                <!-- Absence Editor -->
                <!-- Uncomment if needed
                <AbsenceEditor
                    v-else-if="absence"
                    :date="calendarDate.id"
                    :absence="absence"
                    @update="loader.reload"
                /> -->

                <!-- Availability or Absence Editor -->
                <template v-else>
                    <AvailabilityAbsenceEditor
                        :availability="availability"
                        :absence="absence"
                        :date="dateId"
                        @set:availability="onSetAvailability"
                        @set:absence="onSetAbsence"
                    />
                    <!-- Uncomment if needed
                    <AbsenceEditor
                        :date="calendarDate.id"
                        :absence="undefined"
                        @update="loader.reload"
                    /> -->


                    <template v-if="demandSuggestionsForAvailableDay.length > 0">
                        <h3 style="padding-top: 2em">VORSCHLÄGE</h3>

                        <DemandSuggestionSummary :demands="demandSuggestionsForAvailableDay"></DemandSuggestionSummary>
                    </template>


                    <h3 style="padding-top: 2em">DIENST EINTRAGEN</h3>

                    <IonButton
                        color="light"
                        @click="showRegisterBooking = true"
                    >
                        Neuen Dienst eintragen
                    </IonButton>
                </template>
            </template>
        <RegisterBookingModal
            :modelValue="showRegisterBooking"
            :date-preselection="dateId"
        ></RegisterBookingModal>
    </Modal>
</template>

<script setup lang="ts">
import {computed, defineExpose, defineProps, PropType, Ref, ref, watch} from 'vue';
import {generateCalendarDateFromKapitelDateString} from '@/helper/calendar/calendarDate';
import {generateAvailability} from '@/helper/availability';
import {fetchDayForDate, getDemandSuggestionsForAvailableDay} from '@/helper/day';
import BookingDetails from '@/views/CalendarDetail/components/BookingDetails.vue';
import AvailabilityAbsenceEditor from '@/components/AvailabilityAbsenceEditor.vue';
import {DataLoader} from '@/helper/dataLoader';
import Skeleton from '@/components/Skeleton.vue';
import Modal from "@/components/Modal.vue";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import date from "@/helper/datetime/date";
import {Absence, Availability} from "@/graphql/generated/graphql";
import {IonButton} from "@ionic/vue"
import RegisterBookingModal from "@/views/components/RegisterBookingModal.vue";
import {getShortJobsiteDescription} from "@/helper/jobsite";
import DemandSuggestionSummary from "@/components/DemandSuggestionSummary.vue";

const emit = defineEmits(['set:availability', 'set:absence'])

const onSetAvailability = (availability:Availability) => {
    emit("set:availability", availability, false)
}
const onSetAbsence = (absence:Absence) => {
    emit("set:absence", absence, false)
}
export type ExpectedContent = "booking" | "absence" | "availability" | "demandSuggestions" | "empty" | undefined

const props = defineProps({
    expectedContent: {
        type: String as PropType<ExpectedContent>,
        required: false,
        default: undefined
    },
});

const showRegisterBooking = ref(false)

const dateId: Ref<kapitelDateString|undefined> = defineModel({type: String as PropType<kapitelDateString>})
const isOpen : Ref<Boolean> = ref(!!dateId.value)
watch(()=>dateId.value, value=>
{

    isOpen.value=!!value
    if(isOpen.value){
        if(availability.value || booking.value || absence.value){
            loader.reload()
        }else{
            loader.load()
        }
    }
})
watch(()=>isOpen.value, value=>{
    if(!value){
        dateId.value = undefined
    }
} )

const day = ref();
const availability = ref();
const booking = ref();
const absence = ref();

const kapitelDate = computed(() => {
    if(dateId.value){
        const calendarDate = generateCalendarDateFromKapitelDateString(dateId.value);
        return calendarDate.id
    }
    return undefined
});

const loader = new DataLoader([
    async () => {
        if(!kapitelDate.value){
            return
        }
        day.value = await fetchDayForDate(
            kapitelDate.value,
            'availability {effectiveJobsites {id}, differsFromTemplate}'
        );
        availability.value = day.value.availability || generateAvailability(kapitelDate.value);
        absence.value = day.value.absence || undefined;
        booking.value = day.value.booking || undefined;
    }
]);

const demandSuggestionsForAvailableDay = computed(()  => day.value ? getDemandSuggestionsForAvailableDay(day.value) : [])

if (isOpen.value) {
    loader.load()
}

const reload = () => {
    loader.load();
};

defineExpose({
    reload
});
</script>
